// import React from "react";
// import Laptop from "../assets/laptop.jpg";
// import works from "../assets/sample/works.png";
// import gmail from "../assets/logos/gmail_1.png";
// import stripe from "../assets/logos/stripe.svg";
// import sheet from "../assets/logos/sheet.svg";
// import calendly from "../assets/logos/calendly.svg";

// const HowItWorks = () => {
//   return (
//     <div className="w-full bg-gray-100 py-16 px-4">
//       <div className="max-w-[900px] mx-auto  md:grid-cols-2 flex flex-col justify-center text-center bg-blue-200">
//         <p className="text-[#00df9a] font-bold ">About ctrl(CV)</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Transform Your Business with Ctrl(CV) using Seamless Automation
//         </h1>
//       </div>
//       <div className="flex max-w-[1240px]  justify-center text-center mx-auto">
//         <div className="max-w-[1140px] w-1/2 mx-auto flex flex-col ">
//           <div className="flex  flex-col justify-center mx-auto my-4">
//             <p className="text-[#00df9a] font-bold">
//               DATA HowItWorks DASHBOARD
//             </p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//               Imporve productivity by using ControlCV Automation
//             </h1>
//             <p>
//               Lorem ipsum dolor sit amet consectetur adipisicing elit.
//               Voluptatum molestiae delectus culpa hic assumenda, voluptate
//               reprehenderit dolore autem cum ullam sed odit perspiciatis.
//               Doloribus quos velit, eveniet ex deserunt fuga?
//             </p>
//             <div className="p-6 ">
//               <div className="flex flex-col p-3 bg-white rounded-xl mb-4">
//                 <h1 className="text-2xl">01 - Defin eteh trigger STep</h1>
//                 <p>
//                   Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                   Voluptatum molestiae delectus culpa hic assumenda,
//                 </p>
//               </div>

//               <div className="flex flex-col p-3 bg-white rounded-xl mb-4">
//                 <h1 className="text-2xl">01 - Defin eteh trigger STep</h1>
//                 <p>
//                   Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                   Voluptatum molestiae delectus culpa hic assumenda,
//                 </p>
//               </div>

//               <div className="flex flex-col p-3 bg-white rounded-xl">
//                 <h1 className="text-2xl">01 - Defin eteh trigger STep</h1>
//                 <p>
//                   Lorem ipsum dolor sit amet consectetur adipisicing elit.
//                   Voluptatum molestiae delectus culpa hic assumenda,
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="max-w-[1140px] w-1/2 flex flex-col ">
//           <div className="relative mx-auto my-4">
//             <div className=" w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300 bg-gray-200  transform rotate-[8deg] mt-5 ml-3 z-10">
//               BACK
//             </div>
//             <div className="bg-neutral-100 w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300  absolute top-4 left-0 -translate-y-1/8 -translate-x-1/8 z-0">
//               <div>
//                 <div className="bg-black text-white rounded-md m-3 p-1 w-32">
//                   Start point
//                 </div>
//               </div>
//               <div>
//                 <div className="flex border-gray-200 shadow-lg rounded-lg m-4">
//                   <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                     <img
//                       className="w-[50px] h-[50px] p-1 m-1"
//                       src={stripe}
//                       alt="/"
//                     />
//                   </div>
//                   <div lassName="flex flex-col">
//                     <div className="bg-blue-100 text-xs text-blue-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                       <p>Trigger</p>
//                     </div>
//                     <h1 className="text-md p-2">When a new purchase is made</h1>
//                   </div>
//                 </div>

//                 <div className="flex items-center justify-center">
//                   <div className="bg-gray-200 rounded-full p-1 ">
//                     <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                       <svg
//                         className="text-gray-500 w-4 h-4"
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M12 4v16m8-8H4"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="flex border-gray-200 shadow-lg rounded-lg m-4">
//                   <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                     <img
//                       className="w-[50px] h-[50px] p-1 m-1"
//                       src={sheet}
//                       alt="/"
//                     />
//                   </div>
//                   <div lassName="flex flex-col">
//                     <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                       <p>Action</p>
//                     </div>
//                     <h1 className="text-md p-2">
//                       Create a New Scheduled Booking
//                     </h1>
//                   </div>
//                 </div>

//                 <div className="flex items-center justify-center">
//                   <div className="bg-gray-200 rounded-full p-1 ">
//                     <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                       <svg
//                         className="text-gray-500 w-4 h-4"
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M12 4v16m8-8H4"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="flex border-gray-200 shadow-lg rounded-lg m-4">
//                   <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                     <img
//                       className="w-[50px] h-[50px] p-1 m-1"
//                       src={calendly}
//                       alt="/"
//                     />
//                   </div>
//                   <div lassName="flex flex-col">
//                     <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                       <p>Action</p>
//                     </div>
//                     <h1 className="text-md p-2">
//                       Create a Card in a Specified Board
//                     </h1>
//                   </div>
//                 </div>

//                 <div className="flex items-center justify-center">
//                   <div className="bg-gray-200 rounded-full p-1 ">
//                     <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                       <svg
//                         className="text-gray-500 w-4 h-4"
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M12 4v16m8-8H4"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="flex border-gray-200 shadow-lg rounded-lg m-4">
//                   <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                     <img
//                       className="w-[50px] h-[50px] p-1 m-1"
//                       src={gmail}
//                       alt="/"
//                     />
//                   </div>
//                   <div lassName="flex flex-col">
//                     <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                       <p>Action</p>
//                     </div>
//                     <h1 className="text-md p-2">Send and Onboarding email</h1>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HowItWorks;

// import React from "react";
// // import Laptop from "../assets/laptop.jpg";
// // import works from "../assets/sample/works.png";
// import gmail from "../assets/logos/gmail_1.png";
// import stripe from "../assets/logos/stripe.svg";
// import sheet from "../assets/logos/sheet.svg";
// // import calendly from "../assets/logos/calendly.svg";
// import trello from "../assets/logos/trello.svg";

// const HowItWorks = () => {
//   return (
//     <div className="w-full bg-gray-100 py-16 px-4">
//       {/* <div className="max-w-[900px] mx-auto md:grid md:grid-cols-2 flex flex-col justify-center text-center bg-blue-200">
//         <p className="text-[#00df9a] font-bold">About Ctrl(CV)</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Transform Your Business with Ctrl(CV) using Seamless Automation
//         </h1>
//       </div> */}
//       <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
//         <div className="md:w-1/2 w-full px-4">
//           <div className="flex flex-col justify-center mx-auto my-4">
//             <p className="text-green-700 font-bold">How it Works</p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl text-left font-sans font-bold py-2">
//               Improve productivity 🚀 by using ControlCV Automation ⚡️
//             </h1>
//             <p className="text-left">
//               Streamlining your operations for maximum efficiency. Our process
//               is designed to be simple and effective, so you can focus on what
//               matters most while we handle the rest.
//             </p>
//             <div className="p-6">
//               <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl mb-4">
//                 <h1 className="text-2xl font-bold text-left">
//                   01 - Onboarding
//                 </h1>
//                 <p className="text-left">
//                   Onboarding takes just 30 minutes and it's the only mandatory
//                   call in our service. We can set one up as soon as tomorrow.
//                 </p>
//               </div>

//               <div className="flex flex-col p-3 bg-white  border border-gray-500 rounded-xl mb-4">
//                 <h1 className="text-2xl text-left font-bold">
//                   02 - Add projects
//                 </h1>
//                 <p className="text-left">
//                   We use a simple, self-directed Trello board to manage work.
//                   You can add projects, leave comments, and view progress or let
//                   us add tasks for you.
//                 </p>
//               </div>

//               <div className="flex flex-col p-3 border border-gray-500 bg-white rounded-xl">
//                 <h1 className="text-2xl text-left font-bold">
//                   03 - Weekly Consultations
//                 </h1>
//                 <p className="text-left">
//                   Operations is an ongoing process. We provide (optional) weekly
//                   45-minute consultations for brainstorming & discussion.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-1/2 w-full relative">
//           <div className="w-full max-w-[540px] mx-auto">
//             <div className="relative mx-auto my-4">
//               <div className="w-full max-w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300 bg-gray-500 transform rotate-[8deg] mt-5 ml-3 z-10">
//                 {/* BACK */}
//               </div>
//               <div className="bg-gray-200 w-full max-w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300 absolute top-4 left-0 -translate-y-1/8 -translate-x-1/8 z-0">
//                 <div>
//                   <div className="bg-black  text-white rounded-md m-3 p-1 w-32">
//                     Start point
//                   </div>
//                 </div>
//                 <div className="space-y-4">
//                   <div className="flex bg-white border-gray-200 shadow-lg rounded-lg m-4">
//                     <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                       <img
//                         className="w-[50px] h-[50px] p-1 m-1"
//                         src={stripe}
//                         alt="Stripe"
//                       />
//                     </div>
//                     <div className="flex flex-col p-1">
//                       <div className="bg-blue-100 text-xs text-blue-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                         <p>Trigger</p>
//                       </div>
//                       <h1 className="text-md font-bold p-2">
//                         When a new purchase is made
//                       </h1>
//                     </div>
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex bg-white shadow-lg rounded-lg m-4">
//                     <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                       <img
//                         className="w-[50px] h-[50px] p-1 m-1"
//                         src={sheet}
//                         alt="Sheet"
//                       />
//                     </div>
//                     <div className="flex flex-col p-1">
//                       <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                         <p>Action</p>
//                       </div>
//                       <h1 className="text-md font-bold p-2">
//                         Create a New Scheduled Booking
//                       </h1>
//                     </div>
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex bg-white shadow-lg rounded-lg m-4">
//                     <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                       <img
//                         className="w-[50px] h-[50px] p-1 m-1"
//                         src={trello}
//                         alt="trello"
//                       />
//                     </div>
//                     <div className="flex flex-col p-1">
//                       <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                         <p>Action</p>
//                       </div>
//                       <h1 className="text-md font-bold p-2">
//                         Create a Card in a Specified Board
//                       </h1>
//                     </div>
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex bg-white shadow-lg rounded-lg m-4">
//                     <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
//                       <img
//                         className="w-[50px] h-[50px] p-1 m-1"
//                         src={gmail}
//                         alt="Gmail"
//                       />
//                     </div>
//                     <div className="flex flex-col p-1">
//                       <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
//                         <p>Action</p>
//                       </div>
//                       <h1 className="text-md font-bold p-2">
//                         Send a confirmation email
//                       </h1>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HowItWorks;

import React from "react";
import gmail from "../assets/logos/gmail_1.png";
import stripe from "../assets/logos/stripe.svg";
import sheet from "../assets/logos/sheet.svg";
import trello from "../assets/logos/trello.svg";

const HowItWorks = () => {
  return (
    <div className="w-full bg-gray-100 py-16 px-4">
      <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
        <div className="md:w-1/2 w-full px-4">
          <div className="flex flex-col justify-center mx-auto my-4">
            <p className="text-green-700 font-bold">How it Works</p>
            <h1 className="md:text-4xl sm:text-3xl text-2xl text-left font-sans font-bold py-2">
              Improve productivity 🚀 by using ControlCV Automation ⚡️
            </h1>
            <p className="text-left">
              Streamlining your operations for maximum efficiency. Our process
              is designed to be simple and effective, so you can focus on what
              matters most while we handle the rest.
            </p>
            <div className="p-6">
              <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl mb-4">
                <h1 className="text-2xl font-bold text-left">
                  01 - Onboarding
                </h1>
                <p className="text-left">
                  Onboarding takes just 30 minutes and it's the only mandatory
                  call in our service. We can set one up as soon as tomorrow.
                </p>
              </div>

              <div className="flex flex-col p-3 bg-white  border border-gray-500 rounded-xl mb-4">
                <h1 className="text-2xl text-left font-bold">
                  02 - Add projects
                </h1>
                <p className="text-left">
                  We use a simple, self-directed Trello board to manage work.
                  You can add projects, leave comments, and view progress or let
                  us add tasks for you.
                </p>
              </div>

              <div className="flex flex-col p-3 border border-gray-500 bg-white rounded-xl">
                <h1 className="text-2xl text-left font-bold">
                  03 - Weekly Consultations
                </h1>
                <p className="text-left">
                  Operations is an ongoing process. We provide (optional) weekly
                  45-minute consultations for brainstorming & discussion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full relative">
          <div className="w-full max-w-[540px] mx-auto">
            <div className="relative mx-auto my-4">
              <div className="w-full max-w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300 bg-gray-500 transform rotate-[8deg] mt-5 ml-3 z-10">
                {/* BACK */}
              </div>
              <div className="bg-gray-200 w-full max-w-[540px] h-[540px] rounded-xl shadow-md border border-gray-300 absolute top-4 left-0 -translate-y-1/8 -translate-x-1/8 z-0">
                <div>
                  <div className="bg-black text-white rounded-md m-3 p-1 w-32">
                    Start point
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex bg-white border-gray-200 shadow-lg rounded-lg m-4">
                    <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
                      <img
                        className="w-12 h-12 p-1 m-1"
                        src={stripe}
                        alt="Stripe"
                      />
                    </div>
                    <div className="flex flex-col p-1">
                      <div className="bg-blue-100 text-xs text-blue-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
                        <p>Trigger</p>
                      </div>
                      <h1 className="text-md font-bold p-2">
                        New purchase is made
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="bg-gray-200 rounded-full p-1">
                      <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
                        <svg
                          className="text-gray-500 w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flex bg-white shadow-lg rounded-lg m-4">
                    <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
                      <img
                        className="w-12 h-12 p-1 m-1"
                        src={sheet}
                        alt="Sheet"
                      />
                    </div>
                    <div className="flex flex-col p-1">
                      <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
                        <p>Action</p>
                      </div>
                      <h1 className="text-md font-bold p-2">
                        New scheduled booking
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="bg-gray-200 rounded-full p-1">
                      <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
                        <svg
                          className="text-gray-500 w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flex bg-white shadow-lg rounded-lg m-4">
                    <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
                      <img
                        className="w-12 h-12 p-1 m-1"
                        src={trello}
                        alt="Trello"
                      />
                    </div>
                    <div className="flex flex-col p-1">
                      <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
                        <p>Action</p>
                      </div>
                      <h1 className="text-md font-bold p-2">
                        New card in a workspace
                      </h1>
                    </div>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="bg-gray-200 rounded-full p-1">
                      <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
                        <svg
                          className="text-gray-500 w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="flex bg-white shadow-lg rounded-lg m-4">
                    <div className="bg-white border border-gray-200 shadow-lg rounded-lg m-2">
                      <img
                        className="w-12 h-12 p-1 m-1"
                        src={gmail}
                        alt="Gmail"
                      />
                    </div>
                    <div className="flex flex-col p-1">
                      <div className="bg-green-100 text-xs text-green-600 rounded-sm m-1 p-1 w-14 h-5 flex justify-center items-center">
                        <p>Action</p>
                      </div>
                      <h1 className="text-md font-bold p-2">
                        Confirmation email sent
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
