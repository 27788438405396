// import React, { useState } from "react";
// import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
// import ccv_logo from "../assets/logos/ctrlcv_logo.svg";

// export default function Navbar() {
//   const [nav, setNav] = useState(false);

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   return (
//     <div className=" flex justify-between items-center h-24 max-w-[2000px] mx-auto px-4">
//       <h1 className="w-full text-3xl font-bold mt-4">
//         <img
//           className="max-w-[200px] max-h-[100px] p-1 m-1 object-contain"
//           src={ccv_logo}
//           alt={`logo`}
//         />
//       </h1>
//       <ul className="md:flex hidden">
//         <li className="p-4">Home</li>
//         <li className="p-4">Company</li>
//         <li className="p-4">Resources</li>
//         <li className="p-4">About</li>
//         <li className="p-4">Contact</li>
//       </ul>
//       <div>
//         <div onClick={handleNav} className="block md:hidden">
//           {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
//         </div>
//         <div>
//           <ul
//             className={
//               nav
//                 ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#ffffff] ease-in-out duration-500"
//                 : "ease-in-out duration-500 fixed left-[-100%]"
//             }
//           >
//             <h1 className="w-full text-3xl font-bold text-[#00df9a] m-4">
//               REACT.
//             </h1>
//             <li className="p-4 border-b border-gray-600">Home</li>
//             <li className="p-4 border-b border-gray-600">Company</li>
//             <li className="p-4 border-b border-gray-600">Resources</li>
//             <li className="p-4 border-b border-gray-600">About</li>
//             <li className="p-4">Contact</li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import ccv_logo from "../assets/logos/ctrlcv_logo.svg";

export default function Navbar() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-center items-center h-24 max-w-[2000px] mx-auto px-4">
      <h1 className="w-full text-3xl font-bold mt-4 flex justify-center">
        <img
          className="max-w-[200px] max-h-[100px] p-1 m-1 object-contain"
          src={ccv_logo}
          alt="logo"
        />
      </h1>
    </div>
  );
}
