// import React, { useState } from "react";

// const TypeformStyleForm = ({ onBack }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//     options: "",
//     rating: 0,
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-50">
//       <form
//         onSubmit={handleSubmit}
//         className="w-full max-w-lg p-8 bg-white rounded-lg shadow-md"
//       >
//         <button onClick={onBack} className="bg-blue-500 text-white p-2 rounded">
//           Back
//         </button>

//         {/* Name Field */}
//         <div className="mb-6">
//           <label className="block text-lg font-medium text-gray-700 mb-2">
//             What's your name?
//           </label>
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//             placeholder="Type your name"
//           />
//         </div>

//         {/* Email Field */}
//         <div className="mb-6">
//           <label className="block text-lg font-medium text-gray-700 mb-2">
//             What's your email?
//           </label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//             placeholder="Type your email"
//           />
//         </div>

//         {/* Message Field */}
//         <div className="mb-6">
//           <label className="block text-lg font-medium text-gray-700 mb-2">
//             What's your message?
//           </label>
//           <textarea
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//             placeholder="Type your message"
//           />
//         </div>

//         {/* Select Field */}
//         <div className="mb-6">
//           <label className="block text-lg font-medium text-gray-700 mb-2">
//             Select an option
//           </label>
//           <select
//             name="options"
//             value={formData.options}
//             onChange={handleChange}
//             className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//           >
//             <option value="" disabled>
//               Choose an option
//             </option>
//             <option value="Option 1">Option 1</option>
//             <option value="Option 2">Option 2</option>
//             <option value="Option 3">Option 3</option>
//           </select>
//         </div>

//         {/* Rating Field */}
//         <div className="mb-6">
//           <label className="block text-lg font-medium text-gray-700 mb-2">
//             Rate our service
//           </label>
//           <input
//             type="number"
//             name="rating"
//             value={formData.rating}
//             onChange={handleChange}
//             className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//             placeholder="Rate out of 5"
//             min="0"
//             max="5"
//           />
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-3 bg-green-500 text-white rounded-md font-medium hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default TypeformStyleForm;

// --------------------------

// import React, { useState, useEffect } from "react";
// import bg from "../assets/hero_bg.png";

// const TypeformStyleForm = ({ onBack }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//     options: "",
//     rating: 0,
//   });

//   const [isCalendlyLoaded, setIsCalendlyLoaded] = useState(false);

//   useEffect(() => {
//     // Function to handle script loading
//     const handleCalendlyLoad = () => {
//       setIsCalendlyLoaded(true);
//     };

//     // Dynamically inject the Calendly script
//     const script = document.createElement("script");
//     script.src = "https://assets.calendly.com/assets/external/widget.js";
//     script.async = true;
//     script.onload = handleCalendlyLoad;
//     document.body.appendChild(script);

//     return () => {
//       // Cleanup: Remove the script from the document body
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//   };

//   return (
//     <div
//       style={{ backgroundImage: `url(${bg})` }}
//       className="min-h-screen flex items-center justify-center bg-gray-50 p-4"
//     >
//       <div className="flex flex-col lg:flex-row max-w-6xl w-full">
//         {/* Calendly Widget */}
//         <div className="w-full lg:w-full mb-6 lg:mb-0 lg:pr-4">
//           <button
//             onClick={onBack}
//             className="bg-green-500 text-white p-2 rounded mb-4 w-full lg:w-auto"
//           >
//             <p className="text-white font-bold">Back to ctrl[CV]</p>
//           </button>
//           <div
//             className="calendly-inline-widget w-full"
//             data-url="https://calendly.com/testingacc052/30min" // Replace with your Calendly link
//             style={{ minWidth: "100%", height: "700px" }}
//           ></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TypeformStyleForm;

// ------------------------

import React, { useState, useEffect } from "react";
import bg from "../assets/hero_bg.png";
import { getCalApi } from "@calcom/embed-react";

const TypeformStyleForm = ({ onBack }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    options: "",
    rating: 0,
  });

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "demo-meeting" });
      cal("ui", {
        styles: {
          branding: {
            brandColor: "#000000",
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div
      style={{ backgroundImage: `url(${bg})` }}
      className="min-h-screen flex items-center justify-center bg-gray-50 p-4"
    >
      <div className="flex flex-col lg:flex-row max-w-6xl w-full">
        <div className="w-full lg:w-full mb-6 lg:mb-0 lg:pr-4">
          <button
            onClick={onBack}
            className="bg-green-500 text-white p-2 rounded mb-4 w-full lg:w-auto"
          >
            <p className="text-white font-bold">Back to ctrl[CV]</p>
          </button>
          {/* Cal.com Button */}
          <button
            data-cal-namespace="demo-meeting"
            data-cal-link="ctrlcv/demo-meeting"
            data-cal-config='{"layout":"month_view"}'
            className="bg-blue-500 text-white p-2 rounded mb-4 w-full lg:w-auto"
          >
            Schedule a Meeting
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypeformStyleForm;
