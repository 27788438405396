// import React from "react";
// import bg from "../assets/future_bg.png";

// const Book = () => {
//   return (
//     <div
//       className=" max-w-[1240px] w-full py-12 text-white px-4 border m-auto  p-auto  border-gray-300"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3 ">
//         <div className="lg:col-span-2 my-4">
//           <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold p-5">
//             Ready to level ☝🏼up your business workflow with ctrl[CV]🤩?
//           </h1>
//           {/* <p>Sign up to our newsletter and stay up to date.</p> */}
//         </div>
//         <div className="my-4">
//           <div className="flex flex-col sm:flex-row items-center justify-between w-full">
//             {/* <input
//               className="p-3 flex w-full rounded-md text-black"
//               type="email"
//               placeholder="Enter Email"
//             /> */}
//             <button className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3">
//               Notify Me
//             </button>
//           </div>
//           {/* <p>
//             We care bout the protection of your data. Read our{" "}
//             <span className="text-[#00df9a]">Privacy Policy.</span>
//           </p> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Book;

// src/components/Book.jsx

// ----------------------------------------------- REAL

// import React from "react";
// import bg from "../assets/future_bg.png";

// const Book = ({ onShowForm }) => {
//   return (
//     <div
//       className="max-w-[1240px] w-full py-12 text-white px-4 border m-auto p-auto border-gray-300"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
//         <div className="lg:col-span-2 my-4">
//           <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold p-5">
//             Ready to level ☝🏼up your business workflow with ctrl[CV]🤩?
//           </h1>
//           <h1 className=" px-5 text-lg font-bold text-white">
//             A no-strings-attached consultation, 👋 Book your call now.
//           </h1>
//           <p className=" px-5 text-sm text-white"> No credit card required.</p>
//         </div>
//         <div className="my-4">
//           <div className="flex flex-col sm:flex-row items-center justify-between w-full">
//             <button
//               className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3"
//               onClick={onShowForm}
//             >
//               Book Intro Call
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Book;

// -----------------------------------------------

// import React, { useEffect } from "react";
// import bg from "../assets/future_bg.png";
// import { getCalApi } from "@calcom/embed-react";

// const Book = ({ onShowForm }) => {
//   useEffect(() => {
//     (async function () {
//       const cal = await getCalApi({ namespace: "demo-meeting" });
//       cal("ui", {
//         styles: {
//           branding: {
//             brandColor: "#000000",
//           },
//         },
//         hideEventTypeDetails: false,
//         layout: "month_view",
//       });
//     })();
//   }, []);

//   return (
//     <div
//       className="max-w-[1240px] w-full py-12 text-white px-4 border m-auto p-auto border-gray-300"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
//         <div className="lg:col-span-2 my-4">
//           <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold p-5">
//             Ready to level ☝🏼up your business workflow with ControlCV 🤩?
//           </h1>
//           <h1 className="px-5 text-lg font-bold text-white">
//             A no-strings-attached consultation, 👋 Book your call now.
//           </h1>
//           <p className="px-5 text-sm text-white">No credit card required.</p>
//         </div>
//         <div className="my-4">
//           <div className="flex flex-col sm:flex-row items-center justify-between w-full">
//             {/* Updated Button with Cal.com Integration */}
//             <button
//               className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3"
//               data-cal-namespace="discovery-meeting"
//               data-cal-link="ctrlcv/discovery-meeting"
//               data-cal-config='{"layout":"month_view"}'
//             >
//               Book Intro Call
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Book;

// import React, { useEffect } from "react";
// import bg from "../assets/future_bg.png";
// import { getCalApi } from "@calcom/embed-react";

// const Book = ({ onShowForm }) => {
//   useEffect(() => {
//     (async function () {
//       const cal = await getCalApi({ namespace: "discovery-meeting" });
//       cal("ui", {
//         styles: {
//           branding: {
//             brandColor: "#00df9a", // Updated brand color
//           },
//         },
//         hideEventTypeDetails: false,
//         layout: "month_view",
//       });
//     })();
//   }, []);

//   return (
//     <div
//       className="max-w-[1240px] w-full py-12 text-white px-4 border m-auto p-auto border-gray-300"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
//         <div className="lg:col-span-2 my-4">
//           <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold p-5">
//             Ready to level ☝🏼up your business workflow with ControlCV 🤩?
//           </h1>
//           <h1 className="px-5 text-lg font-bold text-white">
//             A no-strings-attached consultation, 👋 Book your call now.
//           </h1>
//           <p className="px-5 text-sm text-white">No credit card required.</p>
//         </div>
//         <div className="my-4">
//           <div className="flex flex-col sm:flex-row items-center justify-between w-full">
//             {/* Updated Button with Cal.com Integration */}
//             <button
//               className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3"
//               data-cal-namespace="discovery-meeting"
//               data-cal-link="ctrlcv/discovery-meeting"
//               data-cal-config='{"layout":"month_view"}'
//             >
//               Book Intro Call
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Book;

import React, { useState } from "react";
import bg from "../assets/future_bg.png";

const Book = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [formStatus, setFormStatus] = useState("");

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validatePhoneNumber = (phoneNumber) => {
    // Remove non-digit characters and check length
    const digits = phoneNumber.replace(/\D/g, "");
    return digits.length >= 10;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "contactNumber") {
      // Format phone number with dashes
      const formattedValue = value
        .replace(/\D/g, "")
        .replace(/^(\d{3})(\d{3})(\d{0,4})$/, "$1-$2-$3")
        .replace(/(-\d{4})$/, "$1");
      setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!validateEmail(formData.email))
      newErrors.email = "Invalid email address";
    if (!formData.contactNumber)
      newErrors.contactNumber = "Contact Number is required";
    else if (!validatePhoneNumber(formData.contactNumber))
      newErrors.contactNumber = "Phone number must contain at least 10 digits";
    if (!formData.message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length === 0) {
      try {
        // Example HTTP request
        await fetch(
          "https://n8n-deployment-u21851.vm.elestio.app/webhook/08cd9d6d-c53a-472c-aab0-a8a408ca8775",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        setFormStatus("Form sent successfully!");
        // Clear form fields
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          message: "",
        });
      } catch (error) {
        setFormStatus("Error sending form. Please try again.");
      }
    } else {
      setFormStatus("");
    }

    setErrors(newErrors);
  };

  return (
    <div
      className="w-full py-12 px-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-center gap-6">
        <div className="lg:w-1/2 lg:text-left text-center">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">
            Ready to level ☝🏼up your business workflow with ControlCV 🤩?
          </h1>
          <h2 className="text-lg font-bold text-white mb-2">
            A no-strings-attached consultation, 👋 Get in touch with us.
          </h2>
          <p className="text-sm text-white">No credit card required.</p>
        </div>
        <div className="lg:w-1/2">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded-lg shadow-lg mx-auto max-w-lg"
          >
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                First Name
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  required
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm">{errors.firstName}</p>
                )}
              </label>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Last Name
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  required
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm">{errors.lastName}</p>
                )}
              </label>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Email
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </label>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Contact Number
                <input
                  type="tel"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  placeholder="123-456-7890"
                  required
                />
                {errors.contactNumber && (
                  <p className="text-red-500 text-sm">{errors.contactNumber}</p>
                )}
              </label>
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Message
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </label>
            </div>
            <button
              type="submit"
              className="bg-[#dfba00] text-black rounded-md font-medium w-full py-3 mt-4"
            >
              Submit
            </button>
            {formStatus && (
              <p
                className={`mt-4 text-center ${
                  formStatus.includes("success")
                    ? "bg-green-500 text-black"
                    : "bg-red-500 text-white"
                } p-2 rounded`}
              >
                {formStatus}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Book;
