// //

// import React from "react";
// import { CheckIcon } from "@heroicons/react/20/solid";

// const Price = () => {
//   const features = ["Feature #1", "Feature #2", "Feature #3"];

//   const pricingPlans = [
//     { title: "Standard", price: "3000", billingCycle: "/month" },
//     { title: "Pro", price: "7000", billingCycle: "/month" },
//     { title: "Enterprise", price: "15000", billingCycle: "/month" },
//   ];

//   return (
//     <div className="w-full py-16 px-4 bg-gray-100">
//       <div className="max-w-[900px] mx-auto text-center mb-8">
//         <p className="text-green-700 font-bold"> Pricing </p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Your Investment
//         </h1>
//       </div>
//       <div className="flex justify-center items-center">
//         <button className="text-white font-bold bg-green-500 w-[450px] rounded-md  my-6 py-3">
//           POPULAR
//         </button>
//       </div>

//       <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8">
//         {pricingPlans.map((plan, index) => (
//           <div
//             key={index}
//             className={`flex flex-col w-full sm:w-[75%] md:w-1/3 p-6 rounded-md border border-gray-500 ${
//               index === 1
//                 ? "bg-green-500 border-green-700 border-8 shadow-lg"
//                 : "bg-white"
//             }`}
//           >
//             <p
//               className={`${
//                 index === 1 ? "text-white" : "text-green-500"
//               } font-bold text-xl p-auto m-auto`}
//             >
//               {plan.title}
//             </p>
//             <div className="flex justify-center items-baseline my-4">
//               <span
//                 className={`${
//                   index === 1 ? "text-white" : "text-black"
//                 } text-xl font-medium`}
//               >
//                 $
//               </span>
//               <span
//                 className={`${
//                   index === 1 ? "text-white" : "text-black"
//                 } text-6xl font-bold px-1`}
//               >
//                 {plan.price}
//               </span>
//               <span
//                 className={`text-xs pt-1 ${
//                   index === 1 ? "text-white" : "text-black"
//                 }`}
//               >
//                 {plan.billingCycle}
//               </span>
//             </div>
//             {/* <p
//               className={`${
//                 index === 1 ? "text-black font-bold  " : "text-green-500"
//               } p-auto m-auto font-bold text-xs`}
//             >
//               {index === 1 ? (
//                 <div className=" w-[500px] h-[40px] justify-center items-center text-xl p-2 m-7 rounded-md bg-white ">
//                   Billed Quarterly
//                 </div>
//               ) : (
//                 "Billed Monthly"
//               )}
//             </p> */}
//             <p
//               className={`${
//                 index === 1 ? "text-white font-bold" : "text-green-500"
//               } p-auto m-auto font-bold text-xs`}
//             >
//               {index === 1 ? (
//                 <div className="w-[500px] h-[40px] flex justify-center items-center text-2xl p-2 m-7  ">
//                   Billed Quarterly
//                 </div>
//               ) : (
//                 "Billed Monthly"
//               )}
//             </p>

//             <span className="border border-b-1 border-gray-100 m-8"></span>
//             <button
//               className={`${
//                 index === 1
//                   ? "bg-white text-black font-bold"
//                   : "bg-black text-white font-medium"
//               } w-[500px] rounded-md  my-6 mx-auto py-3`}
//             >
//               Get Started
//             </button>
//             <div className="p-4">
//               <ul className="space-y-3">
//                 {features.map((feature, idx) => (
//                   <li key={idx} className="flex items-center space-x-4">
//                     <CheckIcon
//                       className={`w-6 h-6 ${
//                         index === 1 ? "text-white " : "text-green-500"
//                       } flex-shrink-0 m-2`}
//                     />
//                     <p className="text-lg text-gray-600">{feature}</p>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Price;

//-----------------------

// import React, { forwardRef } from "react";
// import { CheckIcon } from "@heroicons/react/20/solid";
// import bg from "../assets/hero_bg.png";

// const Price = forwardRef((props, ref) => {
//   const pricingPlans = [
//     {
//       title: "Standard",
//       price: "3500",
//       billingCycle: "/per workflow",
//       features: [
//         "One Time Developed and Deployed Fee, $3500  ",
//         "Maintenance Fee, $1200/per month",
//         "Additional Addons and Customizations Upon Request. *Additional Fee",
//       ],
//       stripe: ["https://buy.stripe.com/00g4gO4qT0HteBy004"],
//     },
//     {
//       title: "Unlimited",
//       price: "2000",
//       billingCycle: "/month",
//       features: [
//         "One Complete Workflow, Developed and Deployed. ",
//         "Ongoing Maintenance.",
//         "Unlimited Customizations.",
//         "Reporting Dashboard",
//       ],
//       stripe: ["https://buy.stripe.com/cN2aFc8H92PBctq147"],
//     },
//     {
//       title: "Ad-hoc",
//       price: "Custom",
//       billingCycle: "",
//       features: [
//         "Comprehensive Documentation",
//         "Post-Project Support: 2 weeks",
//         "Dedicated Account Manager",
//         "Discounts of Future Projects and Addons",
//       ],
//       stripe: [""],
//     },
//   ];

//   return (
//     <div ref={ref} className="price-component w-full py-16 px-4 bg-gray-100">
//       <div className="max-w-[1200px] mx-auto text-center mb-8">
//         <p className="text-green-700 font-bold">Pricing</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Your Investment
//         </h1>
//       </div>
//       <div className="flex justify-center items-center">
//         <button className="text-black font-bold bg-green-500 w-full sm:w-[450px] rounded-md my-6 py-3">
//           POPULAR
//         </button>
//       </div>

//       <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8">
//         {pricingPlans.map((plan, index) => (

//           <div
//             key={index}
//             className={`flex flex-col w-full sm:w-[90%] md:w-1/3 p-6 rounded-md border border-gray-500 ${
//               index === 1
//                 ? "bg-green-500 border-green-300 border-8 shadow-lg"
//                 : "bg-white"
//             }`}
//             style={index === 1 ? { backgroundImage: `url(${bg})` } : {}}
//           >
//             <p
//               className={`${
//                 index === 1 ? "text-black" : "text-green-500"
//               } font-bold text-xl p-auto m-auto`}
//             >
//               {plan.title}
//             </p>
//             <div className="flex justify-center items-baseline my-4">
//               <span
//                 className={`${
//                   index === 1 ? "text-black" : "text-black"
//                 } text-xl font-medium`}
//               >
//                 {index !== 2 ? "$" : ""}
//               </span>
//               <span
//                 className={`${
//                   index === 1 ? "text-black" : "text-black"
//                 } text-6xl font-bold px-1`}
//               >
//                 {plan.price}
//               </span>
//               <span
//                 className={`text-xs pt-1 ${
//                   index === 1 ? "text-black" : "text-black"
//                 }`}
//               >
//                 {plan.billingCycle}
//               </span>
//             </div>
//             <p
//               className={`${
//                 index === 1 ? "text-black font-bold" : "text-green-600"
//               } p-auto m-auto font-bold text-xs`}
//             >
//               {index === 1 ? (
//                 <div className="w-full sm:w-[400px] md:w-[300px] h-[40px] flex flex-col justify-center items-center ">
//                   <p className="text-2xl p-2 m-3">Billed Quarterly</p>
//                   <p className=" mx-4 mb-2 text-center">
//                     Commit to 3 months and get UNLIMITED customizations
//                   </p>
//                 </div>
//               ) : index === 2 ? (
//                 <div className="w-full sm:w-[400px] md:w-[330px] h-[40px] flex flex-col justify-center items-center ">
//                   <p className="text-lg p-2 m-1">
//                     For one-off needs, contact sales.
//                   </p>
//                   {/* <p> For one-off needs, contact sales.</p> */}
//                 </div>
//               ) : (
//                 "Billed Per Worflow Development"
//               )}
//             </p>

//             <span className="border border-b-1 border-gray-600 m-8"></span>
//             <button
//               className={`${
//                 index === 1
//                   ? "bg-white text-black font-bold border border-gray-300"
//                   : "bg-black text-white font-medium"
//               } w-full sm:w-[400px] md:w-[300px] rounded-md my-6 mx-auto py-3`}
//               // onClick={props.onShowForm}
//               onClick={() => {
//                 if (plan.stripe && plan.stripe[0]) {
//                   window.location.href = plan.stripe[0];
//                 } else {
//                   props.onShowForm();
//                 }
//               }}
//             >
//               Get Started
//             </button>
//             <div className="p-4">
//               <ul className="space-y-3">
//                 {plan.features.map((feature, idx) => (
//                   <li key={idx} className="flex items-center space-x-4">
//                     <CheckIcon
//                       className={`w-6 h-6 ${
//                         index === 1 ? "text-black " : "text-green-500"
//                       } flex-shrink-0 m-2`}
//                     />
//                     <p className="text-lg text-gray-600">{feature}</p>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// });

// export default Price;

// import React, { useEffect, forwardRef } from "react";
// import { CheckIcon } from "@heroicons/react/20/solid";
// import bg from "../assets/hero_bg.png";
// import { getCalApi } from "@calcom/embed-react";

// const Price = forwardRef((props, ref) => {
//   const pricingPlans = [
//     {
//       title: "Standard",
//       price: "3500",
//       billingCycle: "/per workflow",
//       features: [
//         "One Time Developed and Deployed Fee, $3500  ",
//         "Maintenance Fee, $1200/per month",
//         "Additional Addons and Customizations Upon Request. *Additional Fee",
//       ],
//       stripe: ["https://buy.stripe.com/00g4gO4qT0HteBy004"],
//     },
//     {
//       title: "Unlimited",
//       price: "2000",
//       billingCycle: "/month",
//       features: [
//         "One Complete Workflow, Developed and Deployed.",
//         "Ongoing Maintenance.",
//         "Unlimited Customizations.",
//         "Reporting Dashboard",
//       ],
//       stripe: ["https://buy.stripe.com/cN2aFc8H92PBctq147"],
//     },
//     {
//       title: "Ad-hoc",
//       price: "Custom",
//       billingCycle: "",
//       features: [
//         "Comprehensive Documentation",
//         "Post-Project Support: 2 weeks",
//         "Dedicated Account Manager",
//         "Discounts on Future Projects and Addons",
//       ],
//       stripe: [""], // No Stripe link for Ad-hoc
//     },
//   ];

//   useEffect(() => {
//     (async function () {
//       const cal = await getCalApi({ namespace: "discovery-meeting" });
//       cal("ui", {
//         styles: {
//           branding: {
//             brandColor: "#0d6d23", // Set your brand color here
//           },
//         },
//         hideEventTypeDetails: false,
//         layout: "month_view",
//       });
//     })();
//   }, []);

//   return (
//     <div ref={ref} className="price-component w-full py-16 px-4 bg-gray-100">
//       <div className="max-w-[1200px] mx-auto text-center mb-8">
//         <p className="text-green-700 font-bold">Pricing</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Your Investment
//         </h1>
//       </div>
//       <div className="flex justify-center items-center">
//         <button className="text-black font-bold bg-green-500 w-full sm:w-[450px] rounded-md my-6 py-3">
//           POPULAR
//         </button>
//       </div>

//       <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8">
//         {pricingPlans.map((plan, index) => (
//           <div
//             key={index}
//             className={`flex flex-col w-full sm:w-[90%] md:w-1/3 p-6 rounded-md border border-gray-500 ${
//               index === 1
//                 ? "bg-green-500 border-green-300 border-8 shadow-lg"
//                 : "bg-white"
//             }`}
//             style={index === 1 ? { backgroundImage: `url(${bg})` } : {}}
//           >
//             <p
//               className={`${
//                 index === 1 ? "text-black" : "text-green-500"
//               } font-bold text-xl p-auto m-auto`}
//             >
//               {plan.title}
//             </p>
//             <div className="flex justify-center items-baseline my-4">
//               <span
//                 className={`${
//                   index === 1 ? "text-black" : "text-black"
//                 } text-xl font-medium`}
//               >
//                 {index !== 2 ? "$" : ""}
//               </span>
//               <span
//                 className={`${
//                   index === 1 ? "text-black" : "text-black"
//                 } text-6xl font-bold px-1`}
//               >
//                 {plan.price}
//               </span>
//               <span
//                 className={`text-xs pt-1 ${
//                   index === 1 ? "text-black" : "text-black"
//                 }`}
//               >
//                 {plan.billingCycle}
//               </span>
//             </div>
//             <p
//               className={`${
//                 index === 1 ? "text-black font-bold" : "text-green-600"
//               } p-auto m-auto font-bold text-xs`}
//             >
//               {index === 1 ? (
//                 <div className="w-full sm:w-[400px] md:w-[300px] h-[40px] flex flex-col justify-center items-center ">
//                   <p className="text-2xl p-2 m-3">Billed Quarterly</p>
//                   <p className=" mx-4 mb-2 text-center">
//                     Commit to 3 months and get UNLIMITED customizations
//                   </p>
//                 </div>
//               ) : index === 2 ? (
//                 <div className="w-full sm:w-[400px] md:w-[330px] h-[40px] flex flex-col justify-center items-center ">
//                   <p className="text-lg p-2 m-1">
//                     For one-off needs, contact sales.
//                   </p>
//                 </div>
//               ) : (
//                 "Billed Per Workflow Development"
//               )}
//             </p>

//             <span className="border border-b-1 border-gray-600 m-8"></span>
//             <button
//               className={`${
//                 index === 1
//                   ? "bg-green-500 text-white font-medium"
//                   : index === 0
//                   ? "bg-black text-white font-light"
//                   : "bg-black text-white font-bold border border-gray-300"
//               } w-full sm:w-[400px] md:w-[300px] rounded-md my-6 mx-auto py-3`}
//               onClick={() => {
//                 if (index === 0 || index === 1) {
//                   // Redirect to Stripe for Standard and Unlimited plans
//                   window.location.href = plan.stripe[0];
//                 } else if (index === 2) {
//                   // Ad-hoc plan: Trigger Cal.com booking
//                   const calButton = document.querySelector(
//                     '[data-cal-link="ctrlcv/discovery-meeting"]'
//                   );
//                   if (calButton) calButton.click();
//                 }
//               }}
//             >
//               Get Started
//             </button>
//             <div className="p-4">
//               <ul className="space-y-3">
//                 {plan.features.map((feature, idx) => (
//                   <li key={idx} className="flex items-center space-x-4">
//                     <CheckIcon
//                       className={`w-6 h-6 ${
//                         index === 1 ? "text-black " : "text-green-500"
//                       } flex-shrink-0 m-2`}
//                     />
//                     <p className="text-lg text-gray-600">{feature}</p>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// });

// export default Price;

import React, { useEffect, forwardRef } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import bg from "../assets/hero_bg.png";
import { getCalApi } from "@calcom/embed-react";

const Price = forwardRef((props, ref) => {
  const pricingPlans = [
    {
      title: "Standard",
      price: "3500",
      billingCycle: "/per workflow",
      features: [
        "One Time Developed and Deployed Fee, $3500  ",
        "Maintenance Fee, $1200/per month",
        "Additional Addons and Customizations Upon Request. *Additional Fee",
      ],
      stripe: ["https://buy.stripe.com/00g4gO4qT0HteBy004"],
    },
    {
      title: "Unlimited",
      price: "2000",
      billingCycle: "/month",
      features: [
        "One Complete Workflow, Developed and Deployed.",
        "Ongoing Maintenance.",
        "Unlimited Customizations.",
        "Reporting Dashboard",
      ],
      stripe: ["https://buy.stripe.com/cN2aFc8H92PBctq147"],
    },
    {
      title: "Ad-hoc",
      price: "Custom",
      billingCycle: "",
      features: [
        "Comprehensive Documentation",
        "Post-Project Support: 2 weeks",
        "Dedicated Account Manager",
        "Discounts on Future Projects and Addons",
      ],
      stripe: [""], // No Stripe link for Ad-hoc
    },
  ];

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "discovery-meeting" });
      cal("ui", {
        styles: {
          branding: {
            brandColor: "#0d6d23", // Set your brand color here
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div ref={ref} className="price-component w-full py-16 px-4 bg-gray-100">
      <div className="max-w-7xl mx-auto text-center mb-8">
        <p className="text-green-700 font-bold">Pricing</p>
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Your Investment
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <button className="text-black font-bold bg-green-500 w-full sm:w-80 rounded-md my-6 py-3">
          POPULAR
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`flex flex-col w-full p-6 rounded-md border border-gray-500 ${
              index === 1
                ? "bg-green-500 border-green-300 border-8 shadow-lg"
                : "bg-white"
            }`}
            style={index === 1 ? { backgroundImage: `url(${bg})` } : {}}
          >
            <p
              className={`${
                index === 1 ? "text-black" : "text-green-500"
              } font-bold text-xl p-auto m-auto`}
            >
              {plan.title}
            </p>
            <div className="flex justify-center items-baseline my-4">
              <span
                className={`${
                  index === 1 ? "text-black" : "text-black"
                } text-xl font-medium`}
              >
                {index !== 2 ? "$" : ""}
              </span>
              <span
                className={`${
                  index === 1 ? "text-black" : "text-black"
                } text-6xl font-bold px-1`}
              >
                {plan.price}
              </span>
              <span
                className={`text-xs pt-1 ${
                  index === 1 ? "text-black" : "text-black"
                }`}
              >
                {plan.billingCycle}
              </span>
            </div>
            <p
              className={`${
                index === 1 ? "text-black font-bold" : "text-green-600"
              } p-auto m-auto font-bold text-xs`}
            >
              {index === 1 ? (
                <div className="w-full h-[80px] flex flex-col justify-center items-center ">
                  <p className="text-lg p-2 m-1">Billed Quarterly</p>
                  <p className=" mx-4 mb-2 text-center">
                    Commit to 3 months and get UNLIMITED customizations
                  </p>
                </div>
              ) : index === 2 ? (
                <div className="w-full h-[80px] flex flex-col justify-center items-center ">
                  <p className="text-lg p-2 m-1">
                    For one-off needs, contact sales.
                  </p>
                </div>
              ) : (
                "Billed Per Workflow Development"
              )}
            </p>

            <span className="border border-b-1 border-gray-600 m-8"></span>
            <button
              className={`${
                index === 1
                  ? "bg-green-500 text-white font-medium"
                  : index === 0
                  ? "bg-black text-white font-light"
                  : "bg-black text-white font-bold border border-gray-300"
              } w-full rounded-md my-6 py-3`}
              onClick={() => {
                if (index === 0 || index === 1) {
                  // Redirect to Stripe for Standard and Unlimited plans
                  window.location.href = plan.stripe[0];
                } else if (index === 2) {
                  // Ad-hoc plan: Trigger Cal.com booking
                  const calButton = document.querySelector(
                    '[data-cal-link="ctrlcv/discovery-meeting"]'
                  );
                  if (calButton) calButton.click();
                }
              }}
            >
              Get Started
            </button>
            <div className="p-4">
              <ul className="space-y-3">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center space-x-4">
                    <CheckIcon
                      className={`w-6 h-6 ${
                        index === 1 ? "text-black " : "text-green-500"
                      } flex-shrink-0 m-2`}
                    />
                    <p className="text-lg text-gray-600">{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Price;
