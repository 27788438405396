// import React from "react";
// import { ReactTyped } from "react-typed";
// import heroImage from "../assets/hero.png";
// import sheet from "../assets/logos/sheet.svg";
// import cal from "../assets/logos/calendly.svg";
// import stripe from "../assets/logos/stripe.svg";
// import trello from "../assets/logos/trello.svg";
// import gmail from "../assets/logos/gmail_1.png";
// import canva from "../assets/logos/canva.svg";
// import dropbox from "../assets/logos/dropbox.svg";
// import chimp from "../assets/logos/chimp.svg";
// import ai from "../assets/logos/ai.svg";
// import quickbooks from "../assets/logos/quickbooks.svg";
// import drive from "../assets/logos/drive.svg";
// import clickup from "../assets/logos/clickup.svg";
// import hubspot from "../assets/logos/hubspot.svg";
// import salesforce from "../assets/logos/salesforce.svg";
// import fb from "../assets/logos/fb.svg";
// import notion from "../assets/logos/notion.svg";
// import link from "../assets/logos/link.svg";
// import outlook from "../assets/logos/outlook.svg";
// import excel from "../assets/logos/excel.svg";
// import bg from "../assets/hero_bg.png";

// const Hero = () => {
//   return (
//     <div className="text-black">
//       {/* <div
//         className="bg-no-repeat bg-cover max-w-[1800px] w-full h-[86vh] mt-8 mx-auto text-center flex flex-col justify-top"
//         style={{ backgroundImage: `url(${heroImage})` }}
//       > */}
//       {/* ⚡️💼💻🧑🏾‍💻👨🏼‍💻👩🏼‍💻🎯 🧩 🤩☝🏼 */}
//       <div
//         className="bg-no-repeat bg-cover max-w-[1800px] w-full h-[85.1vh] mt-8 mx-auto text-center flex flex-col justify-top"
//         style={{ backgroundImage: `url(${bg})` }}
//       >
//         <p className="text-black font-bold p-2">Workflow Automation Tool</p>
//         <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
//           Harness the Power 🥊 of Automation to Optimize ⚡️ Your Business 💼
//         </h1>
//         <div className="flex justify-center items-center">
//           <p className="md:text-3xl sm:text-4xl text-xl font-bold py-4">
//             We Automate...
//           </p>
//           <ReactTyped
//             className="md:text-3xl sm:text-4xl text-xl font-bold md:pl-2 pl-2"
//             strings={[
//               "Lead Generation",
//               "CRM Management",
//               "Customer Support",
//               "E-commerce Operations",
//               "Project Management",
//               "Customer Feedback",
//               "Inventory Management",
//               "Travel and Booking Management",
//               "Legal Document Automation",
//               "Real Estate Management",
//               "Fitness and Wellness",
//               "Subscription and Membership Management",
//             ]} // chnage these for typing
//             typeSpeed={50}
//             backSpeed={50}
//             loop
//           />
//         </div>
//         {/* <p className="md:text-2xl text-xl font-bold text-gray-500">
//           Monitor your data analytics to increase revenue for BTB, BTC, & SASS
//           platforms.
//         </p> */}
//         <button className="bg-green-500 w-[200px] font-bold rounded-md  my-6 mx-auto py-3 text-white">
//           Get Started
//         </button>

//         <div className="flex flex-col  m-2 mr-4 p-2 h-full">
//           <div className="flex">
//             <div className="flex ml-20 mr-4 border-green-50 border-8 flex-col w-[300px] h-[170px] rounded-xl bg-white ">
//               <div className="flex p-2 m-2  ">
//                 <div className="flex justify-center mr-2  bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={sheet}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center  bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={cal}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//               <div className="font-bold text-lg text-left m-1 p-1">
//                 Set event in Google Calendar from new Google Forms
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[170px] border-green-50 border-8 rounded-xl bg-white ">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={stripe}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={trello}
//                     alt="cal Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={gmail}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//               <div className="font-bold text-lg text-left m-1 p-1">
//                 Set event in Google Calendar from new Google Forms
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[170px] border-green-50 border-8 rounded-xl bg-white ">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={canva}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={dropbox}
//                     alt="cal Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center  bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={chimp}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//               <div className="font-bold text-lg text-left m-1 p-1">
//                 Set event in Google Calendar from new Google Forms
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[170px] border-green-50 border-8 rounded-xl bg-white ">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={drive}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={ai}
//                     alt="cal Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={quickbooks}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//               <div className="font-bold text-lg text-left m-1 p-1">
//                 Set event in Google Calendar from new Google Forms
//               </div>
//             </div>
//           </div>

//           <div className="flex mt-8">
//             <div className="flex ml-20 mr-4 flex-col w-[300px] overflow-hidden h-[50px] rounded-t-xl bg-white border border-gray-200">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={clickup}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={gmail}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[50px] overflow-hidden rounded-t-xl bg-white border border-gray-200">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={hubspot}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={salesforce}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[50px] overflow-hidden rounded-t-xl bg-white border border-gray-200">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={fb}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={notion}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="flex mr-4 flex-col w-[300px] h-[50px] overflow-hidden rounded-t-xl bg-white border border-gray-200">
//               <div className="flex p-2 m-2 ">
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1  object-contain"
//                     src={excel}
//                     alt="sheet Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={outlook}
//                     alt="cal Logo"
//                   />
//                 </div>
//                 <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                   <img
//                     className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                     src={link}
//                     alt="cal Logo"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;

import React from "react";
import { ReactTyped } from "react-typed";
// import heroImage from "../assets/hero.png";
import sheet from "../assets/logos/sheet.svg";
import cal from "../assets/logos/calendly.svg";
import stripe from "../assets/logos/stripe.svg";
import trello from "../assets/logos/trello.svg";
import gmail from "../assets/logos/gmail_1.png";
import canva from "../assets/logos/canva.svg";
import dropbox from "../assets/logos/dropbox.svg";
import chimp from "../assets/logos/chimp.svg";
import ai from "../assets/logos/ai.svg";
import quickbooks from "../assets/logos/quickbooks.svg";
import drive from "../assets/logos/drive.svg";
// import clickup from "../assets/logos/clickup.svg";
// import hubspot from "../assets/logos/hubspot.svg";
// import salesforce from "../assets/logos/salesforce.svg";
// import fb from "../assets/logos/fb.svg";
// import notion from "../assets/logos/notion.svg";
// import link from "../assets/logos/link.svg";
// import outlook from "../assets/logos/outlook.svg";
// import excel from "../assets/logos/excel.svg";
import bg from "../assets/hero_bg.png";

const Hero = ({ onShowPrice }) => {
  return (
    <div className="text-black">
      <div
        className="bg-no-repeat bg-cover max-w-full overflow-hidden w-full h-[85.1vh] mt-8 mx-auto text-center flex flex-col justify-top"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <p className="text-black font-bold p-2">ControlCV</p>
        {/* <h1 className="md:text-8xl sm:text-6xl text-4xl font-bold md:py-6">
          Harness the Power 🥊 of Automation to Optimize ⚡️ Your Business 💼
        </h1> */}
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Harness the Power 🥊 of Automation to Optimize ⚡️ Your Business 💼
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-3xl sm:text-3xl text-xl font-bold py-4">
            We Automate...
          </p>
          <ReactTyped
            className="md:text-3xl sm:text-3xl text-xl font-bold md:pl-2 pl-2"
            strings={[
              "Lead Generation",
              "CRM Management",
              "Customer Support",
              "E-commerce Operations",
              "Project Management",
              "Customer Feedback",
              "Inventory Management",
              "Travel Management",
              "Legal Documents",
              "Real Estate Management",
              "Fitness and Wellness",
              "Membership Management",
            ]}
            typeSpeed={50}
            backSpeed={50}
            loop
          />
        </div>
        <button
          className="bg-green-500 w-[200px] font-bold rounded-md  my-6 mx-auto py-3 text-white"
          onClick={onShowPrice}
        >
          Get Started
        </button>

        <div className="flex flex-col  m-2 mr-4 p-2 h-full">
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-[300px] h-[170px] mb-4 mx-4 rounded-xl bg-white border-8 border-green-50 ">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={sheet}
                    alt="sheet"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={cal}
                    alt="cal"
                  />
                </div>
              </div>
              <div className="font-bold text-lg text-left m-1 p-1">
                Set event in Google Sheets from new Calendly
              </div>
            </div>

            <div className="flex flex-col w-[300px] h-[170px] mb-4 mx-4 rounded-xl bg-white border-8 border-green-50">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={stripe}
                    alt="stripe"
                  />
                </div>
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={trello}
                    alt="trello"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={gmail}
                    alt="gmail"
                  />
                </div>
              </div>
              <div className="font-bold text-lg text-left m-1 p-1">
                Payment with Stripe,lead in Trello, email with Gmail
              </div>
            </div>

            <div className="flex flex-col w-[300px] h-[170px] mb-4 mx-4 rounded-xl bg-white border-8 border-green-50">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={canva}
                    alt="canva"
                  />
                </div>
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={dropbox}
                    alt="dropbox"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={chimp}
                    alt="chimp"
                  />
                </div>
              </div>
              <div className="font-bold text-lg text-left m-1 p-1">
                Graphic with Canva, send to Dropbox, send with Mailchimp
              </div>
            </div>

            <div className="flex flex-col w-[300px] h-[170px] mb-4 mx-4 rounded-xl bg-white border-8 border-green-50">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={drive}
                    alt="drive"
                  />
                </div>
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={ai}
                    alt="ai"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={quickbooks}
                    alt="quickbooks"
                  />
                </div>
              </div>
              <div className="font-bold text-lg text-left m-1 p-1">
                Bill in Google Drive, text with OpenAI, add to Quickbooks
              </div>
            </div>
          </div>

          {/* <div className="flex flex-wrap justify-center mt-8">
            <div className="flex flex-col w-[300px] h-[50px] mb-4 mx-4 rounded-t-xl bg-white border border-gray-200">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={clickup}
                    alt="clickup"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={hubspot}
                    alt="hubspot"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={salesforce}
                    alt="salesforce"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[300px] h-[50px] mb-4 mx-4 rounded-t-xl bg-white border border-gray-200">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={fb}
                    alt="fb"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={notion}
                    alt="notion"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={link}
                    alt="link"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[300px] h-[50px] mb-4 mx-4 rounded-t-xl bg-white border border-gray-200">
              <div className="flex p-2 m-2">
                <div className="flex justify-center mr-2 bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={outlook}
                    alt="outlook"
                  />
                </div>
                <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
                  <img
                    className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                    src={excel}
                    alt="excel"
                  />
                </div>
              </div>
            </div> 
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Hero;
