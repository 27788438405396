// import React from "react";
// // import Laptop from "../assets/laptop.jpg";
// // import works from "../assets/sample/works.png";
// import gmail from "../assets/logos/gmail_1.png";
// import stripe from "../assets/logos/stripe.svg";
// import sheet from "../assets/logos/sheet.svg";
// // import calendly from "../assets/logos/calendly.svg";
// import trello from "../assets/logos/trello.svg";

// const AICalls = () => {
//   return (
//     <div className="w-full bg-green-500 py-16 px-4">
//       <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
//         <div className="md:w-1/2 w-full px-4">
//           <div className="flex flex-col justify-center mx-auto my-4">
//             <p className="text-green-700 font-bold">AI Voice & Chat Agents</p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-sans font-bold py-2 text-left">
//               All-In-One Business Communication Using AI Voice & Chat Agents
//             </h1>
//             <p className="text-left">
//               Connect with prospects and customers across voice, SMS, email, and
//               WhatsApp. Automate workflows with 400+ integrations and Al magic,
//               and watch your business grow on autopilot. 24/7, 365.
//             </p>
//             <div className="mt-4">
//               <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl w-52">
//                 <button className="text-2xl font-bold ">Book a Demo</button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-1/2 w-full relative bg-gray-400"></div>
//       </div>
//     </div>
//   );
// };

// export default AICalls;

// // ------------------------------------------

// import React, { useEffect } from "react";
// import { getCalApi } from "@calcom/embed-react";
// import bg from "../assets/hero_bg.png";

// const AICalls = () => {
//   useEffect(() => {
//     (async function () {
//       const cal = await getCalApi({ namespace: "demo-meeting" });
//       cal("ui", {
//         styles: {
//           branding: {
//             brandColor: "#000000",
//           },
//         },
//         hideEventTypeDetails: false,
//         layout: "month_view",
//       });
//     })();
//   }, []);

//   return (
//     <div
//       className="w-full  py-16 px-4"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
//         <div className="md:w-1/2 w-full px-4">
//           <div className="flex flex-col justify-center mx-auto my-4">
//             <p className="text-green-700 font-bold">AI Voice & Chat Agents</p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-sans font-bold py-2 text-left">
//               All-In-One Business Communication Using AI Voice & Chat Agents
//             </h1>
//             <p className="text-left">
//               Connect with prospects and customers across voice, SMS, email, and
//               WhatsApp. Automate workflows with 400+ integrations and AI magic,
//               and watch your business grow on autopilot. 24/7, 365.
//             </p>
//             <div className="mt-4">
//               <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl w-52">
//                 {/* Updated Button with Cal.com Integration */}
//                 <button
//                   className="text-2xl font-bold"
//                   data-cal-namespace="demo-meeting"
//                   data-cal-link="ctrlcv/demo-meeting"
//                   data-cal-config='{"layout":"month_view"}'
//                 >
//                   Book a Demo
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-1/2 w-full relative bg-gray-400"></div>
//       </div>
//     </div>
//   );
// };

// export default AICalls;

// import React, { useEffect } from "react";
// import { getCalApi } from "@calcom/embed-react";
// import bg from "../assets/hero_bg.png";
// import customerserv from "../assets/customerserv.jpg";

// const AICalls = () => {
//   useEffect(() => {
//     (async function () {
//       const cal = await getCalApi({ namespace: "demo-meeting" });
//       cal("ui", {
//         styles: {
//           branding: {
//             brandColor: "#000000",
//           },
//         },
//         hideEventTypeDetails: false,
//         layout: "month_view",
//       });
//     })();
//   }, []);

//   return (
//     <div
//       className="w-full py-16 px-4 border border-green-600"
//       style={{ backgroundImage: `url(${bg})` }}
//     >
//       <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
//         <div className="md:w-1/2 w-full px-4">
//           <div className="flex flex-col justify-center mx-auto my-4">
//             <p className="text-green-700 font-bold">AI Voice & Chat Agents</p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-sans font-bold py-2 text-left">
//               All-In-One Business Communication Using AI Voice & Chat Agents
//             </h1>
//             <p className="text-left">
//               Connect with prospects and customers across voice, SMS, email, and
//               WhatsApp. Automate workflows with 400+ integrations and AI magic,
//               and watch your business grow on autopilot. 24/7, 365.
//             </p>
//             <div className="mt-4">
//               <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl w-52">
//                 {/* Updated Button with Cal.com Integration */}
//                 <button
//                   className="text-2xl font-bold"
//                   data-cal-namespace="discovery-meeting"
//                   data-cal-link="ctrlcv/discovery-meeting"
//                   data-cal-config='{"layout":"month_view"}'
//                 >
//                   Book a Demo
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           className="md:w-1/2 w-full relative  border-green-600 border-8 rounded-lg"
//           style={{
//             backgroundImage: `url(${customerserv})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//           }}
//         >
//           <div className="absolute inset-0 flex items-center justify-center">
//             <img
//               src={customerserv}
//               alt="Profile"
//               className="w-9 h-9 rounded-full m-2 border-4 object-cover border-gray-300"
//               style={{ opacity: 0 }} // Hide the image if it's only for background
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AICalls;

import React, { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";
import bg from "../assets/hero_bg.png";
import customerserv from "../assets/customerserv.jpg";

const AICalls = () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "demo-meeting" });
      cal("ui", {
        styles: {
          branding: {
            brandColor: "#000000",
          },
        },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div
      className="w-full py-16 px-4 border border-green-600"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col md:flex-row max-w-[1240px] justify-center text-center mx-auto">
        {/* Left Section */}
        <div className="md:w-1/2 w-full px-4">
          <div className="flex flex-col justify-center mx-auto my-4">
            <p className="text-green-700 font-bold">AI Voice & Chat Agents</p>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-sans font-bold py-2 text-left">
              All-In-One Business Communication Using AI Voice & Chat Agents
            </h1>
            <p className="text-left">
              Connect with prospects and customers across voice, SMS, email, and
              WhatsApp. Automate workflows with 2000+ integrations and AI magic,
              and watch your business grow on autopilot. 24/7, 365.
            </p>
            <div className="mt-4">
              <div className="flex flex-col p-3 bg-white border border-gray-500 rounded-xl w-52 mx-auto md:mx-0">
                <button
                  className="text-2xl font-bold"
                  data-cal-namespace="discovery-meeting"
                  data-cal-link="ctrlcv/discovery-meeting"
                  data-cal-config='{"layout":"month_view"}'
                >
                  Book a Demo
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div
          className="md:w-1/2 w-full relative border-green-600 border-8 rounded-lg mt-6 md:mt-0 h-64 md:h-auto"
          style={{
            backgroundImage: `url(${customerserv})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
    </div>
  );
};

export default AICalls;
