// import React from "react";
// import Laptop from "../assets/laptop.jpg";
// import loop from "../assets/sample/loop.png";
// import f500k from "../assets/sample/500k.png";
// import f400 from "../assets/sample/400+.png";
// import Security from "../assets/sample/Security.png";
// import { FaCheck } from "react-icons/fa";
// import gmail from "../assets/logos/gmail_1.png";
// import chimp from "../assets/logos/chimp.svg";
// import ai from "../assets/logos/ai.svg";
// import stripe from "../assets/logos/stripe.svg";
// import trello from "../assets/logos/trello.svg";
// import sheet from "../assets/logos/sheet.svg";
// import canva from "../assets/logos/canva.svg";
// import slack from "../assets/logos/slack.svg";
// import quick from "../assets/logos/quickbooks.svg";
// import dropbox from "../assets/logos/dropbox.svg";
// import hub from "../assets/logos/hubspot.svg";
// import outlook from "../assets/logos/outlook.svg";
// import notion from "../assets/logos/notion.svg";
// import monday from "../assets/logos/monday.svg";
// import ads from "../assets/logos/ads.svg";
// import link from "../assets/logos/link.svg";
// import future from "../assets/future_bg.png";

// const Analytics = () => {
//   return (
//     <div className="w-full bg-white py-16 px-4">
//       <div className="max-w-[900px] mx-auto  md:grid-cols-2 flex flex-col justify-center text-center ">
//         <p className="text-green-700 font-bold ">About ctrl[CV]</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Transform Your Business with Ctrl(CV) using Seamless Automation
//         </h1>
//       </div>
//       <div className="max-w-[1240px] mx-auto flex ">
//         <div className="w-1/2 bg-blue-500 flex-col">
//           <div>
//             <div className=" bg-white flex flex-col p-5">
//               <h1 className="text-6xl font-sans font-bold p-3">
//                 Rapid Integration
//               </h1>
//               <p className="text-md p-3">
//                 Quick, seamless connections for all your automation needs.
//               </p>
//               <div className=" flex flex-col ">
//                 {/* <div className=" bg-red-300">HEY</div> */}
//                 <div className=" flex p-2 gap-x-4 shadow-md rounded-xl">
//                   <div className="flex justify-center bg-white items-center p-1 ">
//                     <img
//                       className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
//                       src={outlook}
//                       alt="outlook Logo"
//                     />
//                     {/* <p className="p-1 ">outlook</p> */}
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 ">
//                     <img
//                       className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
//                       src={notion}
//                       alt="Gmail Logo"
//                     />
//                     {/* <p className="p-1 ">Notion</p> */}
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 ">
//                     <img
//                       className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
//                       src={monday}
//                       alt="ai Logo"
//                     />
//                     {/* <p className="p-1 ">Monday</p> */}
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 ">
//                     <img
//                       className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
//                       src={link}
//                       alt="link Logo"
//                     />
//                     {/* <p className="p-1 ">Link</p> */}
//                   </div>

//                   <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 ">
//                     <img
//                       className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
//                       src={trello}
//                       alt="trello Logo"
//                     />
//                     {/* <p className="p-1 ">Trello</p> */}
//                   </div>

//                   {/* <div className="flex items-center justify-center">
//                     <div className="bg-gray-200 rounded-full p-1">
//                       <div className="bg-white rounded-full w-3 h-3 flex items-center justify-center">
//                         <svg
//                           className="text-gray-500 w-4 h-4"
//                           fill="none"
//                           stroke="currentColor"
//                           viewBox="0 0 24 24"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth={2}
//                             d="M12 4v16m8-8H4"
//                           />
//                         </svg>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={chimp}
//                       alt="Chimp Logo"
//                     />
//                     <p className="p-1 ">Trello</p>
//                   </div> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="flex  w-1/2 flex-col justify-center items-center">
//           {/* <img className=" h-[300px] mx-auto my-2" src={f500k} alt="/" /> */}
//           <div
//             className=" flex flex-col p-5 rounded-lg"
//             style={{ backgroundImage: `url(${future})` }}
//           >
//             <h1 className="text-4xl text-white font-sans font-bold p-1">
//               Future-Proof Your Business
//             </h1>
//             <p className="text-md text-white  p-1">
//               Stay ahead of the competition by adopting cutting-edge automation
//               technologies.
//             </p>
//             <div>
//               <div className="flex border bg-white rounded-lg shadow-xl">
//                 <div className="flex shadow-lg items-center justify-center w-16 h-16 p-3 m-3 bg-green-500 rounded-full">
//                   <FaCheck className="text-white w-16 h-16" />
//                 </div>
//                 <div className="flex w-3/4 flex-col">
//                   {/* <h1>All connected data are saved</h1> */}
//                   <p className="text-sm  p-3">
//                     Enhance customer satisfaction while ensuring consistent and
//                     reliable results.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="max-w-[1240px] mx-auto  flex ">
//         <div className="w-1/3 bg-white flex flex-col p-5">
//           {/* <img
//             className="w-[400px] h-[300px] mx-auto px-6 my-4"
//             src={Security}
//             alt="/"
//           /> */}
//           <h1 className="text-4xl font-sans font-bold p-1">
//             Improved Customer Experience & Enhanced Accuracy
//           </h1>
//           <p className="text-md p-1">Provide faster, more accurate services.</p>
//           <div>
//             <div className="flex border border-gray-200/50 rounded-lg shadow-md">
//               <div className="flex shadow-md items-center justify-center w-16 h-16 p-3 m-3 bg-green-500 rounded-full">
//                 <FaCheck className="text-white w-16 h-16" />
//               </div>
//               <div className="flex w-3/4 flex-col">
//                 {/* <h1>All connected data are saved</h1> */}
//                 <p className="text-sm p-3">
//                   Enhance customer satisfaction while ensuring consistent and
//                   reliable results.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="flex  w-2/3 ">
//           <div className=" bg-gray-50 shadow-md rounded-xl flex flex-col p-5">
//             {/* <img
//             className="w-[400px] h-[300px] mx-auto px-6 my-4"
//             src={Security}
//             alt="/"
//           /> */}
//             <h1 className="text-6xl font-sans font-bold p-3">
//               400+ App Integrations
//             </h1>
//             <p className="text-md p-3">
//               Connect to a variety of apps and software to streamline your
//               worfkflow.
//             </p>
//             <div className=" flex flex-col ">
//               <div className=" flex flex-col">
//                 <div className=" flex p-2 gap-x-4">
//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={gmail}
//                       alt="Gmail Logo"
//                     />
//                     <p className="p-1 ">Gmail</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={chimp}
//                       alt="Gmail Logo"
//                     />
//                     <p className="p-1 ">MailChimp</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={ai}
//                       alt="ai Logo"
//                     />
//                     <p className="p-1 ">OpenAI</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={stripe}
//                       alt="stripe Logo"
//                     />
//                     <p className="p-1 ">Stripe</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={trello}
//                       alt="trello Logo"
//                     />
//                     <p className="p-1 ">Trello</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={sheet}
//                       alt="sheet Logo"
//                     />
//                     <p className="p-1 ">Google Sheet</p>
//                   </div>
//                 </div>

//                 <div className=" flex p-2 gap-x-4">
//                   <div className="flex justify-center bg-white items-center p-1 px-4 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[80px] max-h-[30px] p-1 m-1 object-contain"
//                       src={slack}
//                       alt="slack Logo"
//                     />
//                     <p className="p-2 ">Slack</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={dropbox}
//                       alt="Gmail Logo"
//                     />
//                     <p className="p-1 ">Dropbox</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={canva}
//                       alt="canva Logo"
//                     />
//                     <p className="p-1 ">Canva</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={hub}
//                       alt="hub Logo"
//                     />
//                     <p className="p-1 ">hub</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={monday}
//                       alt="monday Logo"
//                     />
//                     <p className="p-1 ">Monday</p>
//                   </div>

//                   <div className="flex justify-center bg-white items-center p-1 border border-gray-200/50 rounded-md shadow-lg">
//                     <img
//                       className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
//                       src={quick}
//                       alt="quick Logo"
//                     />
//                     <p className="p-1 ">Quickbooks</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Analytics;

// // import React from "react";
// // import loop from "../assets/sample/loop.png";
// // import f500k from "../assets/sample/500k.png";
// // import f400 from "../assets/sample/400+.png";
// // import Security from "../assets/sample/Security.png";

// // const Analytics = () => {
// //   return (
// //     <div className="w-full bg-white py-16 px-4">
// //       <div className="max-w-[900px] mx-auto text-center bg-blue-200 py-8">
// //         <p className="text-[#00df9a] font-bold">About Ctrl(CV)</p>
// //         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
// //           Transform Your Business with Ctrl(CV) using Seamless Automation
// //         </h1>
// //       </div>
// //       <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 py-8">
// //         <div className="col-span-2 flex flex-col justify-center">
// //           <img
// //             className="w-full h-auto mx-auto"
// //             src={loop}
// //             alt="Loop and Branch"
// //           />
// //         </div>
// //         <div className="flex flex-col justify-center">
// //           <img className="w-full h-auto mx-auto" src={f500k} alt="500k" />
// //         </div>
// //       </div>
// //       <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 py-8">
// //         <div className="flex flex-col justify-center">
// //           <img
// //             className="w-full h-auto mx-auto"
// //             src={Security}
// //             alt="Security"
// //           />
// //         </div>
// //         <div className="col-span-2 flex flex-col justify-center">
// //           <img className="w-full h-auto mx-auto" src={f400} alt="400+" />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Analytics;

import React from "react";
import outlook from "../assets/logos/outlook.svg";
import notion from "../assets/logos/notion.svg";
import monday from "../assets/logos/monday.svg";
import link from "../assets/logos/link.svg";
import trello from "../assets/logos/trello.svg";
import gmail from "../assets/logos/gmail_1.png";
import chimp from "../assets/logos/chimp.svg";
import ai from "../assets/logos/ai.svg";
import stripe from "../assets/logos/stripe.svg";
import sheet from "../assets/logos/sheet.svg";
import slack from "../assets/logos/slack.svg";
import dropbox from "../assets/logos/dropbox.svg";
import canva from "../assets/logos/canva.svg";
import hub from "../assets/logos/hubspot.svg";
import fb from "../assets/logos/fb.svg";
import future from "../assets/future_bg.png";
import { FaCheck } from "react-icons/fa";

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[900px] mx-auto text-center">
        <p className="text-green-700 font-bold">About ControlCV</p>
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Transform Your Business with ControlCV using Seamless Automation
        </h1>
      </div>

      <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/2 border border-gray-500 flex flex-col p-5 rounded-lg">
          <h1 className="text-3xl md:text-4xl font-sans font-bold p-3">
            Rapid Integration
          </h1>
          <p className="text-md p-3">
            Quick, seamless connections for all your automation needs.
          </p>

          <div className="flex flex-wrap gap-4">
            {[outlook, notion, monday, link, trello].map((logo, index) => (
              <div key={index} className="flex justify-center items-center p-1">
                <img
                  className="max-w-[50px] max-h-[50px] p-1 m-1 object-contain"
                  src={logo}
                  alt={`logo-${index}`}
                />
              </div>
            ))}
          </div>
        </div>

        <div
          className="w-full lg:w-1/2 flex flex-col justify-center items-center p-5 rounded-lg"
          style={{ backgroundImage: `url(${future})` }}
        >
          <h1 className="text-3xl md:text-4xl text-white font-sans font-bold p-3">
            Future-Proof Your Business
          </h1>
          <p className="text-md text-white p-3">
            Stay ahead of the competition by adopting cutting-edge automation
            technologies.
          </p>

          <div className="flex border bg-white rounded-lg shadow-xl p-4">
            <div className="flex items-center justify-center w-12 h-12 p-3 m-3 bg-green-500 rounded-full">
              <FaCheck className="text-white w-8 h-8" />
            </div>
            <div className="flex w-3/4 flex-col">
              <p className="text-sm p-3">
                Enhance customer satisfaction while ensuring consistent and
                reliable results.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row gap-8 mt-10">
        <div className="w-full lg:w-1/3 bg-white border border-gray-500  flex flex-col p-5 rounded-lg">
          <h1 className="text-3xl md:text-4xl font-sans font-bold p-1">
            Improved Customer Experience & Enhanced Accuracy
          </h1>
          <p className="text-md p-1">Provide faster, more accurate services.</p>

          <div className="flex border border-gray-200 rounded-lg shadow-md p-4 mt-4">
            <div className="flex items-center justify-center w-12 h-12 p-3 m-3 bg-green-500 rounded-full">
              <FaCheck className="text-white w-8 h-8" />
            </div>
            <div className="flex w-3/4 flex-col">
              <p className="text-sm p-3">
                Enhance customer satisfaction while ensuring consistent and
                reliable results.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-2/3 border border-gray-500  bg-gray-50 shadow-md rounded-xl p-5">
          <h1 className="text-3xl md:text-4xl font-sans font-bold p-3">
            2000+ App Integrations
          </h1>
          <p className="text-md p-3">
            Connect to a variety of apps and software to streamline your
            workflow.
          </p>

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4">
            {[
              { src: gmail, name: "Gmail" },
              { src: chimp, name: "MailChimp" },
              { src: ai, name: "OpenAI" },
              { src: stripe, name: "Stripe" },
              { src: trello, name: "Trello" },
              { src: sheet, name: "Google Sheets" },
              { src: slack, name: "Slack" },
              { src: dropbox, name: "Dropbox" },
              { src: canva, name: "Canva" },
              { src: hub, name: "HubSpot" },
              { src: monday, name: "Monday.com" },
              { src: fb, name: "facebook" },
            ].map((logo, index) => (
              <div
                key={index}
                className="flex justify-center items-center p-2 border border-gray-200 rounded-md shadow-lg"
              >
                <img
                  className="max-w-[30px] max-h-[30px] p-1 m-1 object-contain"
                  src={logo.src}
                  alt={logo.name}
                />
                <p className="p-1">{logo.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
