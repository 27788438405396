import React from "react";
import {
  // FaDribbbleSquare,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
  // FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-400">
      <div>
        <h1 className="w-full text-3xl font-bold text-gray-700">ControlCV</h1>
        <p className="py-4">
          Unlock the power of AI and automation to simplify your business
          operations. From chatbots to streamlined workflows, we provide
          solutions that save time and boost efficiency. Let us help you focus
          on what matters most—growing your business.
        </p>
        <p className="py-4 text-black">email us at: info@controlcv.io</p>
        <div className="flex justify-between md:w-[75%] my-6">
          <FaLinkedin size={30} />
          <FaInstagram size={30} />
          <FaYoutube size={30} />
          {/* <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} /> */}
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          {/* <h6 className="font-medium text-gray-600">Solutions</h6> */}
          <ul>
            {/* <li className="py-2 text-sm">Analytics</li>
            <li className="py-2 text-sm">Marketing</li>
            <li className="py-2 text-sm">Commerce</li>
            <li className="py-2 text-sm">Insights</li> */}
          </ul>
        </div>
        <div>
          {/* <h6 className="font-medium text-gray-600">Case Studies</h6> */}
          <ul>
            {/* <li className="py-2 text-sm">Pricing</li>
            <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li> */}
          </ul>
        </div>

        {/* <div>
          <h6 className="font-medium text-gray-600">Legal</h6>
          <ul>
            <li className="py-2 text-sm">Privacy Policy</li>
            <li className="py-2 text-sm">Terms and Conditions</li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
