// // // src/App.jsx
// // import React from "react";
// // // import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// // import "./index.css";
// // import Navbar from "./components/Navbar";
// // import Hero from "./components/Hero";
// // import Setup from "./components/Setup";
// // import HowItWorks from "./components/HowItWorks";
// // import Offers from "./components/Offers";
// // import Price from "./components/Price";
// // import Book from "./components/Book";
// // import Footer from "./components/Footer";
// // import Form from "./components/Form";

// // function App() {
// //   return (
// //     <div>
// //       <Navbar />
// //       <Hero />
// //       <Setup />
// //       <HowItWorks />
// //       <Offers />
// //       <Price />
// //       <Book />
// //       <Footer />
// //     </div>
// //   );
// // }

// // export default App;

// // import React, { useState } from "react";
// // import "./index.css";
// // import Navbar from "./components/Navbar";
// // import Hero from "./components/Hero";
// // import Setup from "./components/Setup";
// // import HowItWorks from "./components/HowItWorks";
// // import Offers from "./components/Offers";
// // import Price from "./components/Price";
// // import Book from "./components/Book";
// // import Footer from "./components/Footer";
// // import Form from "./components/Form";

// // function App() {
// //   const [showForm, setShowForm] = useState(false);

// //   const handleShowForm = () => {
// //     setShowForm(true);
// //   };

// //   const handleHideForm = () => {
// //     setShowForm(false);
// //   };

// //   return (
// //     <div>

// //       {showForm ? (
// //         <Form onBack={handleHideForm} />
// //       ) : (

// //         <Book onShowForm={handleShowForm} />
// //         <Navbar />
// //         <Hero />
// //         <Setup />
// //         <HowItWorks />
// //         <Offers />
// //         <Price />
// //         <Footer />
// //       )}

// //     </div>
// //   );
// // }

// // export default App;

// import React, { useState } from "react";
// import "./index.css";
// import Navbar from "./components/Navbar";
// import Hero from "./components/Hero";
// import Setup from "./components/Setup";
// import HowItWorks from "./components/HowItWorks";
// import Offers from "./components/Offers";
// import Price from "./components/Price";
// import Book from "./components/Book";
// import Footer from "./components/Footer";
// import Form from "./components/Form";

// function App() {
//   const [showForm, setShowForm] = useState(false);

//   const handleShowForm = () => {
//     setShowForm(true);
//   };

//   const handleHideForm = () => {
//     setShowForm(false);
//   };

//   return (
//     <div>
//       {showForm ? (
//         <Form onBack={handleHideForm} />
//       ) : (
//         <>
//           <Navbar />
//           <Hero />
//           <Setup />
//           <HowItWorks />
//           <Offers />
//           <Price />
//           <Book onShowForm={handleShowForm} />
//           <Footer />
//         </>
//       )}
//     </div>
//   );
// }

// export default App;

import React, { useState, useRef } from "react";
import "./index.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Setup from "./components/Setup";
import HowItWorks from "./components/HowItWorks";
import Offers from "./components/Offers";
import Price from "./components/Price";
import Book from "./components/Book";
import Footer from "./components/Footer";
import Form from "./components/Form";
import AICalls from "./components/AICalls";
// import N8nChat from "./components/N8nChat";
// import TypebotComponent from "./components/Typebot";

// import { Bubble } from "@typebot.io/react";
// import Chatbot from "./components/Chatbox";
// import { createChat } from "@n8n/chat";

function App() {
  const [showForm, setShowForm] = useState(false);
  const priceRef = useRef(null); // Create a ref for the PRICE component

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const scrollToPrice = () => {
    if (priceRef.current) {
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {showForm ? (
        <Form onBack={handleHideForm} />
      ) : (
        <>
          <Navbar />
          <Hero onShowPrice={scrollToPrice} />
          <Setup />
          <HowItWorks />
          <Offers />
          <AICalls />
          <Price onShowForm={handleShowForm} ref={priceRef} />
          <Book onShowForm={handleShowForm} />
          <Footer />
          {/* <TypebotComponent /> REAL */}
          {/* <N8nChat /> */}
          {/* <Bubble
            typebot="open-ai-assistant-chat-5k6n8ib"
            theme={{
              button: {
                backgroundColor: "#0042DA",
                customIconSrc:
                  "https://media-typebot-deployment-u21851.vm.elestio.app:39533/typebot/public/workspaces/clzvxc74t0001xs2u9w0pnsaq/typebots/clzx1oqhn000dxs2ub5k6n8ib/bubble-icon?v=1723839319611",
              },
            }}
          /> */}
          {/* <Chatbot webhookUrl="https://n8n-railway-custom-production-c12d.up.railway.app/webhook/da10eba4-1d37-4636-9bb1-67062a4c8c92/chat" /> */}
        </>
      )}
    </div>
  );
}

export default App;
