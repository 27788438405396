// import React from "react";
// import Laptop from "../assets/laptop.jpg";
// import offers from "../assets/sample/offers.png";
// import ppic from "../assets/sample/profile_pic.jpg";
// import file from "../assets/sample/file.png";
// import recruit from "../assets/sample/recruit.png";
// import form from "../assets/sample/form.png";

// const Offers = () => {
//   return (
//     <div className="w-full bg-white py-16 px-4">
//       <div className="max-w-[900px] mx-auto  md:grid-cols-2 flex flex-col justify-center text-center bg-blue-200">
//         <p className="text-[#00df9a] font-bold ">About ctrl(CV)</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Transform Your Business with Ctrl(CV) using Seamless Automation
//         </h1>
//       </div>

//       <div className="flex max-w-[1240px]  justify-center text-center mx-auto ">
//         <div className="max-w-[1140px] w-1/2 mx-auto flex flex-col">
//           <div className=" mx-auto my-4">
//             {/* <img className="w-[540px] h-[540px] mt-6" src={offers} alt="/" /> */}

//             <div className="flex justify-center items-center">
//               <div className="grid grid-cols-2 gap-4">
//                 {/* Block 1 */}
//                 <div className="bg-green-200 border-8  border-white rounded-lg p-4 transform rotate-6 shadow-md h-[200px] z-10">
//                   <div className="flex bg-white border border-gray-200 rounded-xl">
//                     <div className=" ">
//                       <img
//                         src={ppic}
//                         class="w-9 h-9 rounded-full  m-2 border-4 object-cover border-gray-300"
//                       />
//                     </div>
//                     <div className="flex  flex-col">
//                       <h1 className="font-bold text-left mt-1 text-xs">
//                         Jason Bourne
//                       </h1>
//                       <h1 className="text-xs text-left"> Daily Meeting</h1>
//                       <p className="text-xs/[1px] text-left text-left pt-1 pr-1 pb-1">
//                         we start to discuss this document
//                       </p>
//                       <div className="flex m-2">
//                         <div className="flex p-1 mr-2 w-19 border border-gray-200 rounded-md">
//                           <img src={file} class="w-2 h-2 mr-1" />
//                           <p className="text-xs/[1px] mt-1"> filename</p>
//                         </div>
//                         <div className="flex p-1 w-19 border border-gray-200 rounded-md">
//                           <img src={file} class="w-2 h-2 mr-1" />
//                           <p className="text-xs/[1px] mt-1"> filename</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="border text-xs text-white bg-green-700 w-20 mr-2 mb-2 mt-6 rounded-md">
//                     Marketing
//                   </div>
//                   <p className="font-bold text-sm">
//                     Add new Gmail's email to Calendar
//                   </p>
//                 </div>

//                 {/* Block 2 */}
//                 <div className="bg-green-700 border-8  border-white rounded-lg p-4 transform -rotate-3 shadow-md z-20">
//                   <div className="flex bg-white rounded-xl">
//                     <div
//                       className="w-full h-64 bg-cover bg-center"
//                       style={{ backgroundImage: `url(${recruit})` }}
//                     ></div>
//                   </div>
//                   <div className="border text-xs text-black bg-green-200 w-20 mr-2 mb-2 mt-6 rounded-md">
//                     Recruiting
//                   </div>
//                   <p className="font-bold text-white">
//                     Save now Gmail attachments in Google Drive
//                   </p>
//                 </div>

//                 {/* Block 3 */}
//                 <div
//                   className="bg-gray-200 border-8  border-white rounded-lg absolute left-1200 p-4 transform -rotate-6 shadow-md h-[250px] z-10"
//                   style={{ top: "3050px" }}
//                 >
//                   <div className="flex bg-white border border-gray-200  rounded-xl">
//                     <div
//                       className="w-full h-32 bg-cover bg-center"
//                       style={{ backgroundImage: `url(${form})` }}
//                     ></div>
//                   </div>

//                   <div className="border text-xs text-white bg-green-700 w-20 mr-2 mb-2 mt-6 rounded-md">
//                     Marketing
//                   </div>
//                   <p className="font-bold">Add new Gmail's email to Calendar</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-[1140px] w-1/2 mx-auto flex flex-col ">
//           <div className="flex  flex-col justify-center mx-auto my-4">
//             <p className="text-[#00df9a] font-bold">
//               DATA HowItoffers DASHBOARD
//             </p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//               Imporve productivity by using ControlCV Automation 🤩
//             </h1>
//             <p>
//               Lorem ipsum dolor sit amet consectetur adipisicing elit.
//               Voluptatum molestiae delectus culpa hic assumenda, voluptate
//               reprehenderit dolore autem cum ullam sed odit perspiciatis.
//               Doloribus quos velit, eveniet ex deserunt fuga?
//             </p>
//             <div className="p-2 ">
//               <div className="flex p-2 bg-white rounded-2xl  ">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   01 - Marketing
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Recuity
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                    Lead Generation
//                 </h1>
//               </div>

//               <div className="flex p-2 bg-white rounded-2xl  ">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   01 - Content Creation
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   03 - Lead Generation
//                 </h1>
//               </div>

//               <div className="flex p-2 bg-white rounded-2xl  ">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl mr-3 border border-gray-300">
//                   01 - Marketing
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl mr-3 border border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl mr-3 border border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl mr-3 border border-gray-300">
//                   03 - Lead Generation
//                 </h1>
//               </div>
//             </div>
//             {/* <img className="w-[940px] mx-auto my-4" src={Laptop} alt="/" /> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Offers;

// import React from "react";
// import Laptop from "../assets/laptop.jpg";
// import offers from "../assets/sample/offers.png";
// import ppic from "../assets/sample/profile_pic.jpg";
// import file from "../assets/sample/file.png";
// import recruit from "../assets/sample/recruit.png";
// import form from "../assets/sample/form.png";

// const Offers = () => {
//   return (
//     <div className="w-full bg-white py-16 px-4">
//       {/* <div className="max-w-[900px] mx-auto text-center">
//         <p className="text-[#00df9a] font-bold">About ctrl(CV)</p>
//         <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//           Transform Your Business with Ctrl(CV) using Seamless Automation
//         </h1>
//       </div> */}

//       <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-4 max-w-[1240px] mx-auto">
//         {/* Left Column */}
//         <div className="lg:col-span-1 px-4">
//           <div className="my-4">
//             {/* Uncomment this if needed */}
//             {/* <img className="w-full h-auto mt-6" src={offers} alt="Offers" /> */}

//             <div className="grid gap-4">
//               {/* Block 1 */}
//               <div className="bg-green-200 border-8 border-white rounded-lg p-4 transform rotate-6 shadow-md h-[270px] w-[300px] z-10">
//                 <div className="flex bg-white border border-gray-200 rounded-xl">
//                   <div className="flex-shrink-0">
//                     <img
//                       src={ppic}
//                       alt="Profile"
//                       className="w-9 h-9 rounded-full m-2 border-4 object-cover border-gray-300"
//                     />
//                   </div>
//                   <div className="flex flex-col">
//                     <h1 className="font-bold text-left mt-1 text-xs">
//                       Jason Bourne
//                     </h1>
//                     <h1 className="text-xs text-left">Daily Meeting</h1>
//                     <p className="text-xs text-left pt-1 pr-1 pb-1">
//                       We start to discuss this document
//                     </p>
//                     <div className="flex m-2">
//                       <div className="flex p-1 mr-2 w-19 border border-gray-200 rounded-md">
//                         <img src={file} alt="File" className="w-2 h-2 mr-1" />
//                         <p className="text-xs mt-1">Filename</p>
//                       </div>
//                       <div className="flex p-1 w-19 border border-gray-200 rounded-md">
//                         <img src={file} alt="File" className="w-2 h-2 mr-1" />
//                         <p className="text-xs mt-1">Filename</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="border text-xs text-white bg-green-700 w-20 mr-2 mb-2 mt-6 rounded-md">
//                   Marketing
//                 </div>
//                 <p className="font-bold text-sm">
//                   Add new Gmail's email to Calendar
//                 </p>
//               </div>

//               {/* Block 2 */}
//               <div className="bg-green-200 border-8 border-white rounded-lg p-4 transform -rotate-6 shadow-md h-[270px] w-[300px] z-10 mt-[-300px] ml-[270px]">
//                 <div className="flex bg-white border border-gray-200 rounded-xl">
//                   <div className="flex-shrink-0">
//                     <img
//                       src={ppic}
//                       alt="Profile"
//                       className="w-9 h-9 rounded-full m-2 border-4 object-cover border-gray-300"
//                     />
//                   </div>
//                   <div className="flex flex-col">
//                     <h1 className="font-bold text-left mt-1 text-xs">
//                       Jason Bourne
//                     </h1>
//                     <h1 className="text-xs text-left">Daily Meeting</h1>
//                     <p className="text-xs text-left pt-1 pr-1 pb-1">
//                       We start to discuss this document
//                     </p>
//                     <div className="flex m-2">
//                       <div className="flex p-1 mr-2 w-19 border border-gray-200 rounded-md">
//                         <img src={file} alt="File" className="w-2 h-2 mr-1" />
//                         <p className="text-xs mt-1">Filename</p>
//                       </div>
//                       <div className="flex p-1 w-19 border border-gray-200 rounded-md">
//                         <img src={file} alt="File" className="w-2 h-2 mr-1" />
//                         <p className="text-xs mt-1">Filename</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="border text-xs text-white bg-green-700 w-20 mr-2 mb-2 mt-6 rounded-md">
//                   Marketing
//                 </div>
//                 <p className="font-bold text-sm">
//                   Add new Gmail's email to Calendar
//                 </p>
//               </div>

//               {/* Block 3 */}
//               <div className="bg-green-500 border-8 border-white rounded-lg p-4 transform -rotate-3 shadow-md z-20 mt-[-80px]">
//                 <div className="flex bg-white">
//                   <div
//                     className="w-full h-64 bg-cover bg-center"
//                     style={{ backgroundImage: `url(${recruit})` }}
//                   ></div>
//                 </div>
//                 <div className="border text-xs text-black bg-green-200 w-20 mr-2 mb-2 mt-6 rounded-md">
//                   Recruiting
//                 </div>
//                 <p className="font-bold text-white">
//                   Save now Gmail attachments in Google Drive
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Right Column */}
//         <div className="lg:col-span-1 px-4">
//           <div className="flex flex-col justify-center my-4">
//             <p className="text-green-700 font-bold">What We Offer</p>
//             <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
//               Improve productivity by using Ctrl(CV) Automation 🤩
//             </h1>
//             <p>
//               Lorem ipsum dolor sit amet consectetur adipisicing elit.
//               Voluptatum molestiae delectus culpa hic assumenda, voluptate
//               reprehenderit dolore autem cum ullam sed odit perspiciatis.
//               Doloribus quos velit, eveniet ex deserunt fuga?
//             </p>
//             <div className="p-2">
//               <div className="flex flex-wrap p-2 bg-white rounded-2xl">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   01 - Marketing
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   03 - Recruitment
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   04 - Lead Generation
//                 </h1>
//               </div>

//               <div className="flex flex-wrap p-2 bg-white rounded-2xl">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   01 - Content Creation
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   03 - Lead Generation
//                 </h1>
//               </div>

//               <div className="flex flex-wrap p-2 bg-white rounded-2xl">
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   01 - Marketing
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   02 - Sales
//                 </h1>
//                 <h1 className="text-base p-2 bg-gray-100 rounded-2xl border mr-3 border-gray-300">
//                   03 - Lead Generation
//                 </h1>
//               </div>
//             </div>
//             {/* Uncomment this if needed */}
//             {/* <img className="w-full mx-auto my-4" src={Laptop} alt="Laptop" /> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Offers;

import React from "react";
// import Laptop from "../assets/laptop.jpg";
// import offers from "../assets/sample/offers.png";
import ppic from "../assets/sample/profile_pic.jpg";
import ppic2 from "../assets/sample/profile_pic2.png";
import file from "../assets/sample/file.png";
// import recruit from "../assets/sample/recruit.png";
// import form from "../assets/sample/form.png";
import bg from "../assets/future_bg.png";
import cover from "../assets/cover.png";

const Offers = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-4 max-w-[1240px] mx-auto">
        {/* Left Column */}
        <div className="lg:col-span-1 px-4">
          <div className="my-4">
            <div className="grid gap-4">
              {/* Block 1 */}
              <div className="bg-green-200 border-8 border-white rounded-lg p-4 transform rotate-6 shadow-md h-[270px] sm:w-[300px] w-full z-10 mx-auto">
                <div className="flex bg-white border border-gray-200 rounded-xl">
                  <div className="flex-shrink-0">
                    <img
                      src={ppic}
                      alt="Profile"
                      className="w-9 h-9 rounded-full m-2 border-4 object-cover border-gray-300"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-bold text-left mt-1 text-xs">
                      Jason Bourne
                    </h1>
                    <h1 className="text-xs text-left">Daily Meeting</h1>
                    <p className="text-xs text-left pt-1 pr-1 pb-1">
                      We start to discuss this document
                    </p>
                    <div className="flex m-2">
                      <div className="flex p-1 mr-2 w-19 border border-gray-200 rounded-md">
                        <img src={file} alt="File" className="w-2 h-2 mr-1" />
                        <p className="text-xs mt-1">Filename</p>
                      </div>
                      <div className="flex p-1 w-19 border border-gray-200 rounded-md">
                        <img src={file} alt="File" className="w-2 h-2 mr-1" />
                        <p className="text-xs mt-1">Filename</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border text-xs flex justify-center items-center text-white bg-green-700 w-20 mr-2 mb-2 mt-6 rounded-md">
                  Marketing
                </div>
                <p className="font-bold text-sm">
                  Add new Gmail's email to Calendar
                </p>
              </div>

              {/* Block 2 */}
              <div className="bg-blue-200 border-8 border-white rounded-lg p-4 transform -rotate-6 shadow-md h-[270px] sm:w-[300px] w-full z-10 mx-auto mt-[-300px] sm:ml-[270px]">
                <div className="flex bg-white border border-gray-200 rounded-xl">
                  <div className="flex-shrink-0">
                    <img
                      src={ppic2}
                      alt="Profile"
                      className="w-9 h-9 rounded-full m-2 border-4 object-cover border-gray-300"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-bold text-left mt-1 text-xs">
                      KimStewart@gmail.com
                    </h1>
                    <h1 className="text-xs text-left">Daily Meeting</h1>
                    <p className="text-xs text-left pt-1 pr-1 pb-1">
                      Thank you for sigining up, look out for an event schedule
                      meeeting so we can finialize our discussion.
                    </p>
                  </div>
                </div>

                <div className="border text-xs flex justify-center items-center text-white bg-blue-700 w-20 mr-2 mb-2 mt-6 rounded-md">
                  Support
                </div>
                <p className="font-bold text-sm">Add new email to calendar</p>
              </div>

              {/* Block 3 */}
              <div
                className="bg-green-500 bg-no-repeat bg-right-top  border-8 border-white rounded-lg p-4 transform -rotate-3 shadow-md z-20 sm:mt-[-80px] mt-[-50px]"
                style={{ backgroundImage: `url(${bg})` }}
              >
                <div className="flex bg-white">
                  <div
                    className="w-full h-64 bg-cover bg-center"
                    style={{ backgroundImage: `url(${cover})` }}
                    // className="w-full h-64 bg-cover bg-center"
                    // style={{ backgroundImage: `url(${recruit})` }}
                  ></div>
                </div>
                <div className="border text-xs flex justify-center items-center text-black bg-green-200 w-20 mr-2 mb-2 mt-6 rounded-md">
                  Recruiting
                </div>
                <p className="font-bold text-white">
                  Save now Gmail attachments in Google Drive
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="lg:col-span-1 px-4">
          <div className="flex flex-col justify-center my-4">
            <p className="text-green-700 font-bold">What We Offer</p>
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Improve productivity by using ControlCV Automation 🤩
            </h1>
            <p className="text-sm sm:text-base">
              ControlCV Automation streamlines business functions, offering
              automation solutions for tasks like marketing, project management,
              lead generation, and customer support. By simplifying workflows
              and saving time, their tools help businesses focus on growth and
              success.
            </p>
            <div className="p-2 px-4 ">
              <div className="flex flex-wrap justify-center items-center p-2 bg-white rounded-lg">
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Marketing
                </h1>
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Project Management
                </h1>
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Lead Generation
                </h1>
              </div>

              <div className="flex flex-wrap justify-center items-center p-2 bg-white rounded-lg mt-4">
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Customer Support
                </h1>
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Sales
                </h1>
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Email Personalization
                </h1>
              </div>

              <div className="flex flex-wrap justify-center items-center p-2 bg-white rounded-lg mt-4">
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  Appointment Scheduling
                </h1>
                <h1 className="text-xs sm:text-base p-2 mx-2 bg-gray-100 text-center rounded-lg border mb-2 border-gray-300 flex-1 min-w-[150px]">
                  And many more +
                </h1>
              </div>
            </div>

            {/* <div className="p-2">
              <div className="flex flex-wrap p-2 bg-white rounded-lg">
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3  border-gray-300">
                  Marketing
                </h1>
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3  border-gray-300">
                  Project Management
                </h1>
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3  border-gray-300">
                  Lead Generation
                </h1>
              </div>

              <div className="flex flex-wrap p-2 bg-white rounded-lg mt-4">
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3 mb-1 border-gray-300">
                  Customer Support
                </h1>
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3 mb-1 border-gray-300">
                  Sales
                </h1>
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-3 mb-1 border-gray-300">
                  Email Personalization
                </h1>
              </div>

              <div className="flex flex-wrap p-2 bg-white rounded-lg mt-4 ">
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-4 mb-1 border-gray-300">
                  Appointment Scheduling
                </h1>
                <h1 className="text-xs sm:text-base p-2 bg-gray-100 rounded-lg border mr-4 mb-1 border-gray-300">
                  And many more +
                </h1>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
